import React, { useEffect } from 'react';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root-reducer';
import { getEvents } from '../../redux/events/eventsAction';
import { getRestaurants } from '../../redux/restaurants/action';
import { getMainBanner } from '../../redux/main-banner/action';
//RENDER && STYLES
import MainHeader from '../../components/Mainheader';
import MainBanner from '../../components/MainBanner';
import MainFilter from '../../components/MainFilter';
import EventList from '../../components/EventList';
import Loading from '../../commons/Loading';
import Error from '../../commons/Error/index';
import '../styles.scss';

const EventsPage: React.FC = () => {
    const dispatch = useDispatch();

    const { loading, error, events } = useSelector((state: RootState) => state.events);
    const { banner } = useSelector((state: RootState) => state.mainBanner);
    
    useEffect(() => {
        dispatch(getEvents(0));
        dispatch(getRestaurants());
        dispatch(getMainBanner());
    }, [dispatch]);

    if (loading) return <Loading />;
    return (
        <main className="Events">
            <MainHeader />
            <div className="container">
                <MainBanner banner={banner}/>
                <MainFilter />
                {error ? <Error /> : <EventList events={events} />}
            </div>
        </main>
    );
};

export default EventsPage;
