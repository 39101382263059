import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { event as eventType } from '../../types/events';
import { returnImage } from '../../utils/displayImage';
import { AddressSvg } from '../../Assets/Svg/AddressSvg';
import { CreditCardSvg } from '../../Assets/Svg/CreditCardSvg';
import 'moment/locale/ru';
import './styles.scss';

interface EventDetailProps {
    event?: eventType;
}

const EventDetail: React.FC<EventDetailProps> = ({ event }) => {
    return (
        <div className="event-detail">
            <div
                className="event-detail__banner"
                style={{
                    backgroundImage: `url(${event?.PICTURE || src})`,
                }}
            ></div>
            <div className="event-detail__content">
                <div className="event-detail__info">
                    <div className="event-detail__info__type">
                        <div className="event-detail__info__type__ico">
                            <img src={returnImage(event?.TYPE)} alt="" />
                        </div>
                        <div className="event-detail__info__type__name">{event?.TYPE}</div>
                    </div>

                    <div className="event-detail__info__title">{event?.NAME}</div>

                    <div className="event-detail__info__mobile">
                        <div className="event-detail__banner__info__top">
                            <div className="event-detail__banner__info__top__date">
                                <div className="event-detail__banner__info__top__date__day">
                                    {moment(event?.EVENT_DATE, 'DD/MM/YYYY HH:mm').format('DD')}
                                </div>
                                <div className="event-detail__banner__info__top__date__mounth">
                                    {moment(event?.EVENT_DATE, 'DD/MM/YYYY HH:mm').format('MMM').slice(0, 3)}
                                </div>
                            </div>
                            <div className="event-detail__banner__info__top__time">
                                <div className="event-detail__banner__info__top__time__key">Время проведения:</div>
                                <div className="event-detail__banner__info__top__time__val">
                                    {moment(event?.EVENT_DATE, 'DD/MM/YYYY HH:mm').format('LLLL')}
                                </div>
                            </div>
                        </div>

                        <div className="event-detail__banner__info__cent">
                            <div className="event-detail__banner__info__cent__logo">{AddressSvg()}</div>
                            <div className="event-detail__banner__info__cent__content">
                                <div className="event-detail__banner__info__cent__content__key">Адрес:</div>

                                {/* <div className="event-detail__banner__info__cent__content__val">
                {event?.PLACE}
              </div> */}

                                <div className="event-detail__banner__info__cent__content__subval">{event?.ADDRESS}</div>
                            </div>
                        </div>

                        <div className="event-detail__banner__info__cent">
                            <div className="event-detail__banner__info__cent__logo">
                                <img src={event?.RESTAURANT_LOGO} alt="" />
                            </div>
                            <div className="event-detail__banner__info__cent__content">
                                <div className="event-detail__banner__info__cent__content__key">Паб:</div>

                                {/* <div className="event-detail__banner__info__cent__content__val">
                {event?.PLACE}
              </div> */}

                                <div className="event-detail__banner__info__cent__content__subval">{event?.RESTAURANT_NAME}</div>
                            </div>
                        </div>

                        <div className="event-detail__banner__info__cent">
                            <div className="event-detail__banner__info__cent__logo">{CreditCardSvg()}</div>
                            <div className="event-detail__banner__info__cent__content">
                                <div className="event-detail__banner__info__cent__content__key">Стоимость:</div>
                                <div className="event-detail__banner__info__cent__content__subval">
                                    {event?.FREE_EVENT === 'N' ? 'От ' + event?.MIN_PRICE + ' ₽' : 'Bход cвободный'}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="event-detail__info__text" dangerouslySetInnerHTML={{ __html: event?.PREVIEW }} />
                </div>
                <div className="event-detail__banner__info">
                    <div className="event-detail__banner__info__top">
                        <div className="event-detail__banner__info__top__date">
                            <div className="event-detail__banner__info__top__date__day">
                                {moment(event?.EVENT_DATE, 'DD/MM/YYYY HH:mm').format('DD')}
                            </div>
                            <div className="event-detail__banner__info__top__date__mounth">
                                {moment(event?.EVENT_DATE, 'DD/MM/YYYY HH:mm').format('MMM').slice(0, 3)}
                            </div>
                        </div>
                        <div className="event-detail__banner__info__top__time">
                            <div className="event-detail__banner__info__top__time__key">Время проведения:</div>
                            <div className="event-detail__banner__info__top__time__val">
                                {moment(event?.EVENT_DATE, 'DD/MM/YYYY HH:mm').format('LLLL')}
                            </div>
                        </div>
                    </div>

                    <div className="event-detail__banner__info__cent">
                        <div className="event-detail__banner__info__cent__logo">{AddressSvg()}</div>
                        <div className="event-detail__banner__info__cent__content">
                            <div className="event-detail__banner__info__cent__content__key">Адрес:</div>

                            {/* <div className="event-detail__banner__info__cent__content__val">
                {event?.PLACE}
              </div> */}

                            <div className="event-detail__banner__info__cent__content__subval">{event?.ADDRESS}</div>
                        </div>
                    </div>

                    <div className="event-detail__banner__info__cent">
                        <div className="event-detail__banner__info__cent__logo">
                            <img src={event?.RESTAURANT_LOGO} alt="" />
                        </div>
                        <div className="event-detail__banner__info__cent__content">
                            <div className="event-detail__banner__info__cent__content__key">Паб:</div>

                            {/* <div className="event-detail__banner__info__cent__content__val">
                {event?.PLACE}
              </div> */}

                            <div className="event-detail__banner__info__cent__content__subval">{event?.RESTAURANT_NAME}</div>
                        </div>
                    </div>

                    {/* <div className="event-detail__info__type hide-bg">
            <div className="event-detail__info__type__ico">
              <img src={returnImage(event?.TYPE)} alt="" />
            </div>
            <div className="event-detail__info__type__name">{event?.TYPE}</div>
          </div> */}

                    {/* <div className="event-detail__info__title hide-bg">{event?.NAME}</div> */}

                    <div className="event-detail__banner__info__price">
                        {event?.FREE_EVENT === 'N' ? (
                            <>
                                <div className="event-detail__banner__info__price__info">
                                    <div className="event-detail__banner__info__price__info__key">Стоимость от:</div>
                                    <div className="event-detail__banner__info__price__info__val">{event?.MIN_PRICE} ₽</div>
                                </div>
                                <Link to={`/eventcheckout/${event?.ID}`} className="event-detail__banner__info__price__btn">
                                    Купить билеты
                                </Link>
                            </>
                        ) : (
                            <div className="event-detail__banner__info__price__info__val">Bход cвободный</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetail;
const src = 'https://mspmag.com/downloads/51225/download/shutterstock_696332926.jpg?cb=5b21ba3e87f20324f36e2a2e13fe438c&w=1280';
