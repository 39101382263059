import { Table } from "src/types/tickets";

export const StyleTable = (width: number, item: Table) : React.CSSProperties => {
  const seatStyle: React.CSSProperties = {
    left:
      width < 620 && width > 450
        ? Number(item.X) - 30
        : width < 450 && width > 0
        ? Number(item.X) + 200
        : Number(item.X) + 200,
    top: width < 620 && width > 450 ? Number(item.Y) - 55 : Number(item.Y) - 70,
    objectFit: "contain",
    position: "absolute",
    transform: `rotate(${Number(item.ANGLE)}deg)`,
    width: 25,
    height: 25,
    OObjectFit: "contain",
  };
  return seatStyle;
};
