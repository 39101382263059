import { Place } from "src/types/tickets";
import { Action, ActionTypes } from "./types";
import { addItemToBasket } from "./utils";

const initialState = {
  items: [],
};

interface TicketState {
  items: Place[];
}

const ticketReducer = (
  state: TicketState = initialState,
  action: Action
): TicketState => {
  switch (action.type) {
    case ActionTypes.ADD_ITEM_TO_BASKET:
      return {
        items: addItemToBasket(state.items, action.payload),
      };

    case ActionTypes.REMOVE_ITEM_FROM_BASKET:
      return {
        items: state.items.filter(
          (item: Place) => item.ID !== action.payload.ID
        ),
      };
    default:
      return state;
  }
};

export default ticketReducer;
