import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { event } from "../../../types/events";
import "./styles.scss";
interface HeaderProps {
  Event?: event;
}
const Header: React.FC<HeaderProps> = ({ Event }) => {
  return (
    <div className="checkout-header">
      <div className="checkout-header__wrapper">
        <div className="checkout-header__meta">
          <Link to="/" className="checkout-header__meta__logo">
            <img src={Event.RESTAURANT_LOGO} alt="RESTAURANT_LOGO" />
          </Link>
          <div className="checkout-header__meta__info">
            <div className="checkout-header__meta__info__key">Событие:</div>
            <div className="checkout-header__meta__info__val">{Event.NAME}</div>
          </div>
        </div>
        <div className="checkout-header__menu">
          <div className="checkout-header__menu__place">
            <div className="checkout-header__menu__place__ico">
              <img src={Event.RESTAURANT_LOGO} alt="RESTAURANT_LOGO" />
            </div>
            <div className="checkout-header__menu__place__info">
              <div className="checkout-header__menu__place__info__key">
                Место проведения:
              </div>
              <div className="checkout-header__menu__place__info__val">
                {Event.PLACE}
              </div>
              <div className="checkout-header__menu__place__info__subval">
                {Event.ADDRESS}
              </div>
            </div>
          </div>
          <div className="checkout-header__menu__date">
            <div className="checkout-header__menu__date__ico">
              <div className="checkout-header__menu__date__ico__day">
                {moment(Event.EVENT_DATE, "DD/MM/YYYY HH:mm").format("DD")}
              </div>
              <div className="checkout-header__menu__date__ico__mounth">
                {moment(Event.EVENT_DATE, "DD/MM/YYYY HH:mm")
                  .format("MMM")
                  .slice(0, 3)}
              </div>
            </div>

            <div className="checkout-header__menu__date__info">
              <div className="checkout-header__menu__date__info__key">
                Время проведения:
              </div>
              <div className="checkout-header__menu__date__info__val">
                {moment(Event.EVENT_DATE, "DD/MM/YYYY HH:mm").format("LLLL")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
