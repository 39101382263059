import { combineReducers } from 'redux';
import eventsReducer from './events/eventsReducer';
import eventReducer from './eventDetail/eventDetail-reducer';
import ticketReducer from './tickets/ticketsReducer';
import restaurants from './restaurants/reducer';
import mainBanner from './main-banner/reducer';

const rootReducer = combineReducers({
    events: eventsReducer,
    event: eventReducer,
    tickets: ticketReducer,
    restaurants,
    mainBanner,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
