//FORM && VALID
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../../commons/TextInput";
import InputMask from "react-input-mask";
//Loading
import ReactLoading from "react-loading";

interface Values {
  phoneNumber: string;
  email: string;
}

interface FormProps {
  total: number;
  onSubmit: (values: Values) => void;
  loading: boolean;
}
const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),

  phoneNumber: Yup.string()
    .required()
    .min(7, "Invalid Phone Number")
    .label("Phone"),
});

const CartForm: React.FC<FormProps> = ({ total, onSubmit, loading }) => {
  return (
    <Formik
      initialValues={{ email: "", phoneNumber: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <TextInput
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Введите ваш e-mail"
            title="Эл. почта"
            isError={errors.email && touched.email ? true : false}
            error="неверный адрес электронной почты"
          />
          <div className="text-input__container">
            <div className="text-input__title">Телефонный номер</div>
            <InputMask
              className="text-input__input"
              name="phoneNumber"
              placeholder="+7 (999) 999-99-99"
              mask="+7 (999) 999-99-99"
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.phoneNumber && touched.phoneNumber && (
            <div className="text-input__error"> {errors.phoneNumber}</div>
          )}

          <div className="event-cart__itog">
            <div className="event-cart__itog__info">
              <div className="event-cart__itog__info__key">К оплате:</div>
              <div className="event-cart__itog__info__val">{total || 0} ₽</div>
            </div>

            {loading ? (
              <ReactLoading type="spokes" color="#000" height={30} width={30} />
            ) : (
              <button type="submit" className="event-cart__itog__buy">
                Купить билеты
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CartForm;
