import { Place, Table } from "src/types/tickets";

export const StyleSeat = (
  width: number,
  tables: Table,
  seat: Place,
  isSelected: () => Place
): React.CSSProperties => {
  const seatStyle: React.CSSProperties = {
    top:
      width < 620 && width > 450
        ? Number(tables.Y) - Number(seat.Y) - 55 || 0
        : Number(tables.Y) - Number(seat.Y) - 70 || 0,
    left:
      width < 620 && width > 450
        ? Number(tables.X) - Number(seat.X) - 30 || 0
        : width < 450 && width > 0
        ? Number(tables.X) - Number(seat.X) + 200 || 0
        : Number(tables.X) - Number(seat.X) + 200 || 0,
    backgroundColor: seat.RESERVED === "1" ? "#C7C7C7" : seat.COLOR,
    width: 12,
    height: 12,
    borderRadius: isSelected() ? 9 : 6,
    position: "absolute",
    border: isSelected() ? "1px solid black" : "none",
  };
  return seatStyle;
};
