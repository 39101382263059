export const formatDate = (date: string ) : number => {
    const newDate = date.split(".");
 
    const day = newDate[0];
    const month = newDate[1];
    const temp = newDate[2].split(" ");
    const year = temp[0];
    return Number(`${year}${month}${day}`)
};

 export const convertDateToNumber = (date : string) : number=> Number(date.split("-").join(""));