import React from "react";
// REDUX
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
// TYPES
import { RootState } from "../../../../redux/root-reducer";
import { Restaurant } from "../../../../types/restaurants";
// RENDERING && STYLES
import { DisplayFilter, src } from "../../types";
import "./styles.scss";

interface BarProps {
  filterType: DisplayFilter;
  handleFilterBars(id: string): void;
}

interface BarRow {
  handleFilterBars(id: string): void;
}

export const Bars: React.FC<BarProps> = ({ handleFilterBars, filterType }) => {
  const { loading, restaurants, error } = useSelector(
    (state: RootState) => state.restaurants
  );
  console.log(888, filterType);

  if (loading)
    return (
      <ReactLoading type={"spokes"} color={"#000"} height={35} width={35} />
    );
  if (error) return <div>Error</div>;
  return (
    <div
      className={
        "filter_items bars_filter" +
        (filterType === DisplayFilter.RESTAURANTS_FILTER ? " active" : "")
      }
    >
      <div className="bars_container">
        {restaurants.map((restaurant) => (
          <Row
            key={restaurant.id}
            restaurant={restaurant}
            handleFilterBars={handleFilterBars}
          />
        ))}
      </div>
    </div>
  );
};

interface RowProps extends BarRow {
  restaurant: Restaurant;
}

const Row: React.FC<RowProps> = ({ restaurant, handleFilterBars }) => {
  return (
    <div className="bars_row" onClick={() => handleFilterBars(restaurant.id)}>
      <div className="bars_row_box">
        <img
          className="bars_row_img"
          src={restaurant.picture || src}
          alt={""}
        />
        <p className="bars_row_title">{restaurant.title}</p>
      </div>
    </div>
  );
};
