import React from "react";

export enum DisplayFilter {
  EVENTS_FILTER = "EVENTS_FILTER",
  CALENDER_FILTER = "CALENDER_FILTER",
  RESTAURANTS_FILTER = "RESTAURANTS_FILTER",
}

export type EventsType =
  | "Живая музыка"
  | "Спортивная трансляция"
  | "Театральное представление";

export interface FilterItem {
  id: string;
  title: string;
  Icon: React.FC;
  type: DisplayFilter;
  filterNode: React.ReactNode;
}

export interface FilterEvents {
  id: string;
  type: EventsType;
}

export interface CalendarFilterBtn {
  id: number;
  name: string;
  subtract: number;
}

export const src =
  "https://logopond.com/logos/889585449f8f238b46df162273188972.png";
