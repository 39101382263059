import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
//TYPES
import { RouteParams } from "../../types/routes";
import { GetTickets, Map, Option, Ticket } from "../../types/tickets";
//REDUX
import {
  getEventById,
  RestEvent,
} from "../../redux/eventDetail/eventDetail-action";
import { RootState } from "../../redux/root-reducer";
import { useSelector, useDispatch } from "react-redux";
//REQ
import { getEventTickets } from "../../service";
// RENDER && STYLES
import EventCheckoutHeader from "../../components/EventCheckoutHeader";
import { RenderEventMap } from "./RenderEventMap";
import Loading from "../../commons/Loading";
import Error from "../../commons/Error";
import "../styles.scss";

const EventCheckoutPage: React.FC = () => {
  const params = useParams<RouteParams>();
  const id = params.id;
  const [map, setMap] = useState<Map>();
  const [options, setOptions] = useState<Option[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketLoading, setTicketLoading] = useState<boolean>(false);
  const [ticketError, setTicketError] = useState<boolean>(false);
  const { event, loading, error } = useSelector(
    (state: RootState) => state.event
  );
  const dispatch = useDispatch();

  // Reset Event to null
  useEffect(() => {
    RestEvent();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEventById(id));
      setTicketLoading(true);
      getEventTickets(id)
        .then((res: GetTickets) => {
          console.log(res, "---res----");
          const { map, eventTickets, options } = res;
          setMap(map);
          setTickets(eventTickets);
          setOptions(options);
          setTicketLoading(false);
        })
        .catch((err) => {
          setTicketError(true);
          setTicketLoading(false);
        });
    }
  }, [id, dispatch]);

  if (loading || !event) return <Loading />;
  if (error) return <Error />;
  return (
    <div className="event-checkout">
      <EventCheckoutHeader Event={event} />
      <div className="event-checkout-divide">
        <RenderEventMap
          map={map}
          ticketError={ticketError}
          ticketLoading={ticketLoading}
          tickets={tickets}
          options={options}
        />
      </div>
    </div>
  );
};

export default EventCheckoutPage;
