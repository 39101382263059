import React from "react";
import { DisplayFilter, EventsType, FilterEvents } from "../../types";
import { returnImage } from "../../../../utils/displayImage";
import "./styles.scss";

interface EventsProps {
  filterEvents: FilterEvents[];
  filterType: DisplayFilter;
  handleFilterByEvent(type: EventsType): void;
}

export const Events: React.FC<EventsProps> = ({
  filterEvents,
  filterType,
  handleFilterByEvent,
}) => {
  return (
    <div
      className={
        "filter_items events_filter" +
        (filterType === DisplayFilter.EVENTS_FILTER ? " active" : "")
      }
    >
      {filterEvents.map((event) => (
        <div
          key={event.id}
          className="events_filter_items"
          onClick={() => handleFilterByEvent(event.type)}
        >
          <div className="events_filter_items-child">
            <div className="events_filter_items-img_container">
              <img
                className="events_filter_items-img"
                src={returnImage(event.type)}
                alt={event.type}
              />
            </div>
            {event.type}
          </div>
        </div>
      ))}
    </div>
  );
};
