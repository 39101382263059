import React from "react";
import { DisplayFilter } from "../types";
// import { CloseWhiteSvg } from "../../../Assets/Svg/CloseWhiteSvg";
import "../styles.scss";

interface FilterProps {
  Icon: React.FC;
  title: string;
  type: DisplayFilter;
  filterNode: React.ReactNode;
  filterType: DisplayFilter;
  setFilterType(type: DisplayFilter): void;
}

export const Filter: React.FC<FilterProps> = ({
  Icon,
  title,
  type,
  filterNode,
  filterType,
  setFilterType,
}) => {
  // const someFunction = (e: any) => {
  //   e.stopPropagation();
  // };
  return (
    <div
      className="main-filter__item"
      onClick={() => setFilterType(filterType === type ? null : type)}
    >
      <div className="main-filter__item__img">
        <Icon />
      </div>
      <div className="main-filter__item__name">{title}</div>
      {/* <button
        type="button"
        className="main-filter__item__close"
        onClick={someFunction}
      >
        {CloseWhiteSvg()}
      </button> */}
      {filterNode}
    </div>
  );
};
