import { event } from "../../types/events";

export const endpoint = "getEventsListByRestaurantId";

export enum ActionType {
  GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS",
  GET_EVENTS_ERROR = "GET_EVENTS_ERROR",
  FILTER_EVENTS_BY_TYPE = "FILTER_EVENTS_BY_TYPE",
  FILTER_EVENTS_BY_CALENDER = "FILTER_EVENTS_BY_CALENDER",
  FILTER_EVENTS_BY_RESTAURANTS = "FILTER_EVENTS_BY_RESTAURANTS",
  MULTIPLE_FILTER = "MULTIPLE_FILTER",
}

interface EventRequest {
  type: ActionType.GET_EVENTS_REQUEST;
}

interface EventSuccess {
  type: ActionType.GET_EVENTS_SUCCESS;
  payload: event[];
}

interface EventError {
  type: ActionType.GET_EVENTS_ERROR;
  payload: any;
}

export type EventsType =
  | "Живая музыка"
  | "Спортивная трансляция"
  | "Театральное представление";
interface FilterEventByType {
  type: ActionType.FILTER_EVENTS_BY_TYPE;
  payload: EventsType;
}

interface FilterEventByCalender {
  type: ActionType.FILTER_EVENTS_BY_CALENDER;
  payload: number;
}
interface FilterEventByRestaurant {
  type: ActionType.FILTER_EVENTS_BY_RESTAURANTS;
  payload: string;
}

interface MultipleFilter {
  type: ActionType.MULTIPLE_FILTER;
  payload: number;
}

export type Action =
  | EventRequest
  | EventSuccess
  | EventError
  | FilterEventByType
  | FilterEventByCalender
  | FilterEventByRestaurant
  | MultipleFilter;
