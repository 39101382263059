import React from "react";

export function EventsTypeSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0722 4.26804H19.5155V2.6598C19.5155 2.14737 19.1 1.73196 18.5876 1.73196C18.0752 1.73196 17.6598 2.14737 17.6598 2.6598V4.26804H6.34021V2.6598C6.34021 2.14737 5.92479 1.73196 5.41237 1.73196C4.89995 1.73196 4.48454 2.14737 4.48454 2.6598V4.26804H0.927835C0.415412 4.26804 0 4.68346 0 5.19588V21.3402C0 21.8526 0.415412 22.268 0.927835 22.268H23.0722C23.5846 22.268 24 21.8526 24 21.3402V5.19588C24 4.68346 23.5846 4.26804 23.0722 4.26804ZM4.60825 10.1443H11.567V12H4.60825V10.1443ZM19.3608 16.6392H4.60825V14.7835H19.3918L19.3608 16.6392Z"
        fill="white"
      />
    </svg>
  );
}
