import {axios} from "../../service";

import { Dispatch } from "redux";
import { Action, ActionTypes, endpoint, GetEventsResponse } from "./eventDetail-type";

export const getEventById =
  (id: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.GET_SINGLE_EVENT_REQUEST });
    try {
      const { data } = await axios.get<GetEventsResponse>(`${endpoint}/${id}`, );

      dispatch({
        type: ActionTypes.GET_SINGLE_EVENT_SUCCESS,
        payload: Object.values(data.event)[0],
      });
    } catch (error) {
      dispatch({ type: ActionTypes.GET_SINGLE_EVENT_ERROR, payload: error });
    }
  };

export const RestEvent = () => {
  return { type: ActionTypes.REST_EVENT };
};
