import React from "react";
import Calendar from "react-calendar";
// import { CalendarFilterBtn } from "../../types";
import "react-calendar/dist/Calendar.css";
import { DisplayFilter } from "../../types";
import "./styles.scss";

interface CalenderProps {
  value: Date;
  filterType: DisplayFilter;
  setValue(date: Date): void;
  // calendarBtn: CalendarFilterBtn[];
  // handleFilterCalenderBtn(button: CalendarFilterBtn): void;
  // activeCalenderBtn: number;
}

export const Calender: React.FC<CalenderProps> = ({
  setValue,
  value,
  filterType,
  // calendarBtn,
  // handleFilterCalenderBtn,
  // activeCalenderBtn,
}) => {
  return (
    <div
      className={
        "filter_items calender_events" +
        (filterType === DisplayFilter.CALENDER_FILTER ? " active" : "")
      }
    >
      {/* <div className="calender_btn_container">
        {calendarBtn.map((btn) => (
          <button
            key={btn.id}
            className={
              activeCalenderBtn === btn.id
                ? "calender_btn calender_btn_active"
                : "calender_btn"
            }
            onClick={() => handleFilterCalenderBtn(btn)}
          >
            {btn.name}
          </button>
        ))}
      </div> */}
      <Calendar
        onChange={setValue}
        value={value}
        locale="ru"
        className="calender"
        prev2Label=""
        next2Label=""
      />
    </div>
  );
};
