import { ActionType, Action, endpoint } from "./types";
import { Dispatch } from "redux";
import { axios } from "src/service";

export const getRestaurants = () => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionType.GET_RESTAURANT_REQUEST });
  try {
    const { data } = await axios.get(endpoint);
    dispatch({
      type: ActionType.GET_RESTAURANT_SUCCESS,
      payload: data.restaurants,
    });
  } catch (error) {
    dispatch({ type: ActionType.GET_RESTAURANT_ERROR, payload: error });
  }
};
