import { event } from "src/types/events";
import { Action, ActionTypes } from "./eventDetail-type";
const initialState = {
  event: null,
  error: null,
  loading: false,
};

interface EventState {
  event: event | null;
  loading: boolean;
  error: null | string;
}

const eventReducer = (
  state: EventState = initialState,
  action: Action
): EventState => {
  switch (action.type) {
    case ActionTypes.GET_SINGLE_EVENT_REQUEST:
      return {
        loading: true,
        event: null,
        error: null,
      };
    case ActionTypes.GET_SINGLE_EVENT_SUCCESS:
      return {
        loading: false,
        event: action.payload,
        error: null,
      };
    case ActionTypes.GET_SINGLE_EVENT_ERROR:
      return {
        loading: false,
        event: null,
        error: action.payload,
      };
    case ActionTypes.REST_EVENT:
      return {
        loading: false,
        event: null,
        error: null,
      };
    default:
      return state;
  }
};

export default eventReducer;
