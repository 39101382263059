import React, { useState, useEffect } from "react";
//REDUX
import { useSelector } from "react-redux";
import { RootState } from "../../redux/root-reducer";
//REQ
import { buyTicket, payTicket } from "../../service";
// TYPES
import { Place } from "../../types/tickets";
//RENDER && STYLES
import { toast } from "react-toastify";
import EventCartItem from "../EventCartItem";
import Form from "./Form";
import "./styles.scss";

const EventCart: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [orderIdError, setOrderIdError] = useState<boolean>(false);
  const [sperLink, setSperLink] = useState<string | null>(null);
  const [sperLinkError, setSperLinkError] = useState<boolean>(false);

  const { items } = useSelector((state: RootState) => state.tickets);

  const ids = items?.map(function (a) {
    return a.ID;
  });

  console.log(ids);

  let total = items.reduce((acc: number, item: Place) => {
    return Number(item.PRICE) + acc;
  }, 0);

  //  make REQ to pay ticket and get the Sper Link
  useEffect(() => {
    if (orderId) {
      payTicket(orderId)
        .then((res: string) => {
          setSperLink(res);
          console.log(res);
        })
        .catch((err) => {
          setSperLinkError(true);
          setLoading(false);
        });
    }
  }, [orderId, orderIdError]);

  // No Errors send the user to pay
  useEffect(() => {
    if (sperLinkError) return;
    if (sperLink) {
      setLoading(false);
      const win = window.open(sperLink, "_blank");
      win.focus();
    }
  }, [sperLink, sperLinkError]);

  // in case of error display toast. //TODO: Refactor
  useEffect(() => {
    if (orderIdError || sperLinkError) {
      toast.error("Error");
    }
  }, [sperLinkError, orderIdError]);

  // handleSubmit and REQ to buyTicket and get OrderId
  // TODO: replace Rest_id
  const handleSubmit = (values) => {
    if (!items?.length) {
      toast.warn("Пожалуйста, выберите места");
    } else {
      setLoading(true);
      const ids = items.map(function (a) {
        return a.ID;
      });

      let buyer = {
        phone: values.phoneNumber,
        email: values.email,
      };
      buyTicket({ restaurant: 49, buyer, tickets: ids, comment: ":" })
        .then((res: string) => {
          setOrderId(res);
        })
        .catch((err) => {
          setOrderIdError(true);
          setLoading(false);
        });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="event-cart">
        <div className="event-cart__info">
          <div className="event-cart__info__key">Выбрано:</div>
          <div className="event-cart__info__val">{items?.length} билета</div>
        </div>

        <div className="event-cart__content">
          <ul className="event-cart__content__list">
            {items?.length &&
              items.map((item: Place) => (
                <EventCartItem key={item.ID} product={item} />
              ))}
          </ul>
        </div>

        <Form onSubmit={handleSubmit} total={total} loading={loading} />
      </div>
    </div>
  );
};

export default EventCart;
