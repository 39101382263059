import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { LiveMusicSvg } from "../../Assets/Svg/LiveMusicSvg";
import { SportTranslationSvg } from "../../Assets/Svg/SportTranslationSvg";
import { TheatricalPerformanceSvg } from "../../Assets/Svg/TheatricalPerformanceSvg";
import { event } from "../../types/events";
import "./styles.scss";

interface MainBannerProps {
  banner?: event[] | undefined;
}

const MainBanner: React.FC<MainBannerProps> = ({ banner }) => {
  const eventId = Object.keys(banner)[0]
  const bannerEvent = banner[eventId]
  
  return (
    <Link
      to={`/event/${bannerEvent?.ID}`}
      className="main-banner"
      style={{ backgroundImage: `url(${bannerEvent?.PICTURE})` }}
    >
      <div className="main-banner__top">
        <div className="main-banner__top__date">{moment(bannerEvent?.EVENT_DATE, "DD/MM/YYYY HH:mm").format("DD")}</div>
        <div className="main-banner__top__mounth">{moment(bannerEvent?.EVENT_DATE, "DD/MM/YYYY HH:mm").format("D MMMM").split(" ").pop()}</div>
      </div>
      <div className="main-banner__type">
        <div className="main-banner__type__logo">
          {bannerEvent?.TYPE === "Театральное представление" ?
          <TheatricalPerformanceSvg/> :
          bannerEvent?.TYPE === "Живая музыка" ?
          <LiveMusicSvg/> :
          <SportTranslationSvg/>
          }
        </div>
        <div className="main-banner__type__name">{bannerEvent?.TYPE}</div>
      </div>

      <div className="main-banner__name">{bannerEvent?.NAME}</div>

      <div className="main-banner__info">
        <div className="main-banner__info__item">
          <div className="main-banner__info__item__key">Место :</div>
          <div className="main-banner__info__item__value">{bannerEvent?.RESTAURANT_NAME}</div>
        </div>

        <div className="main-banner__info__item">
          <div className="main-banner__info__item__key">Стоимость:</div>
          <div className="main-banner__info__item__value">{bannerEvent?.MIN_PRICE}</div>
        </div>
      </div>
    </Link>
  );
};

export default MainBanner;
