import { Place, Ticket } from "../../../types/tickets";
//HOOKS
import { useWindowDimensions } from "../../../hooks/getWindowDimensions";
//REDUX
import { AddItemToBasket } from "../../../redux/tickets/ticketsAction";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/root-reducer";
import { StyleSeat } from "./styles";

interface SeatsProps {
  seat?: Place;
  tickets: Ticket[];
  index: number;
  filterOption: null | string;
}

//Расширение глобального типа Window
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const Seats: React.FC<SeatsProps> = ({
  seat,
  index,
  tickets,
  filterOption,
}) => {
  const tables = tickets.map((i) => i.params)[index];
  const { items } = useSelector((state: RootState) => state.tickets);
  const dispatch = useDispatch();
  let { width } = useWindowDimensions();

  const handleAddItemToCart = (item: Place): void => {
    // Метод для взаимодействия со столами в нативном приложении для андроид и ios
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(item));
    }
    dispatch(AddItemToBasket(item));
  };

  const isSelected = (): Place => {
    const selected = items.find((item) => seat.ID === item.ID);
    return selected;
  };

  return (
    <div>
      {!filterOption ? (
        <div
          onClick={() => handleAddItemToCart(seat)}
          style={StyleSeat(width, tables, seat, isSelected)}
        />
      ) : (
        filterOption === seat.COLOR && (
          <div
            onClick={() => handleAddItemToCart(seat)}
            style={StyleSeat(width, tables, seat, isSelected)}
          />
        )
      )}
    </div>
  );
};

export default Seats;
