import { event } from "src/types/events";
import {ApiUnknownObject} from '../../types'

export const endpoint = "getEvent";

export enum ActionTypes {
  GET_SINGLE_EVENT_REQUEST = "GET_SINGLE_EVENT_REQUEST",
  GET_SINGLE_EVENT_SUCCESS = "GET_SINGLE_EVENT_SUCCESS",
  GET_SINGLE_EVENT_ERROR = "GET_SINGLE_EVENT_ERROR",
  REST_EVENT = "REST_EVENT",
}


export interface GetEventsResponse {
  event:ApiUnknownObject<event>;
}

interface EventRequest {
  type: ActionTypes.GET_SINGLE_EVENT_REQUEST;
}

interface EventSuccess {
  type: ActionTypes.GET_SINGLE_EVENT_SUCCESS;
  payload: event;
}

interface EventError {
  type: ActionTypes.GET_SINGLE_EVENT_ERROR;
  payload: any;
}
interface RestEvent {
  type: ActionTypes.REST_EVENT;
}

export type Action = EventRequest | EventSuccess | EventError | RestEvent;
