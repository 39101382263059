import { toast } from "react-toastify";
import { Place } from "../../types/tickets";

export const addItemToBasket = (
  cartItems: Place[],
  cartItemToAdd: Place
): Place[] => {
  const existingCartItem = cartItems?.find(
    (cartItem: Place) => cartItem.ID === cartItemToAdd.ID
  );
  if (cartItemToAdd.RESERVED === "1") {
    toast.warn("это место уже зарезервировано");
    return [...cartItems];
  } else {
    if (existingCartItem) {
      return cartItems.filter(
        (items: Place) => items.ID !== existingCartItem.ID
      );
    } else {
      return [...cartItems, cartItemToAdd];
    }
  }
};
