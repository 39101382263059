import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { event } from "../../types/events";
import { returnImage } from "../../utils/displayImage";
import "moment/locale/ru";
import "./styles.scss";

interface EventItemProps {
  event: event;
}

const EventItem: React.FC<EventItemProps> = ({ event }) => {
  return (
    <Link to={`/event/${event.ID}`} className="event-item">
      <img
        src={event.PICTURE || src}
        alt={event.NAME}
        className="event-item__img"
      />
      <div className="event-item__info">
        <div className="event-item__info__top">
          <div className="event-item__info__top__name">
            <div className="event-item__info__top__name__type">
              <img
                src={returnImage(event.TYPE)}
                alt=""
                style={{ width: 20, height: 20 }}
              />
            </div>

            <div className="event-item__info__top__name__text">
              {event.TYPE}
            </div>
          </div>
          <div className="event-item__info__top__date">
            <div className="event-item__info__top__date__num">
              {moment(event.EVENT_DATE, "DD/MM/YYYY HH:mm").format("DD")}
            </div>
            <div className="event-item__info__top__date__mounth">
              {moment(event.EVENT_DATE, "DD/MM/YYYY HH:mm")
                .format("MMM")
                .slice(0, 3)}
            </div>
          </div>
        </div>

        <div className="event-item__info__name">{event.NAME}</div>

        <div className="event-item__info__bottom">
          <div className="event-item__info__bottom__item">
            <div className="event-item__info__bottom__item__key">Место :</div>
            <div className="event-item__info__bottom__item__val">
              {event.PLACE}
            </div>
          </div>
          <div className="event-item__info__bottom__item">
            <div className="event-item__info__bottom__item__key">
              Стоимость:
            </div>
            {event?.FREE_EVENT === "N" ? (
              <>
                <div className="event-item__info__bottom__item__val">
                  От {event.MIN_PRICE} ₽
                </div>
              </>
            ) : (
              <div className="event-item__info__bottom__item__val">
                Bход cвободный
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventItem;
const src =
  "https://mspmag.com/downloads/51225/download/shutterstock_696332926.jpg?cb=5b21ba3e87f20324f36e2a2e13fe438c&w=1280";
