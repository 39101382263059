import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import EventsPage from "./pages/EventsPage";
import EventDetailPage from "./pages/EventDetailPage";
import EventCheckoutPage from "./pages/EventCheckoutPage";

const Navigation: React.FC = () => {
  //https://app.publifegroup.ru/?request=/eventcheckout/26419
  const params = useLocation();
  const eventId = params.search.split("/").pop();
  const paramsName =
    params.search.length > 0 ? params.search.split("=")[0].slice(1) : "";
  const targetPageName =
    params.search.length > 0 ? params.search.split("/")[1] : "";
  console.log(333, eventId);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          //Если идет редирект с битрикса с параметром request то необходимо отлавливаем нужную целевую страницу и делть на нее редирект
          if (params.search.length > 0 && paramsName === "request") {
            if (targetPageName === "event") {
              return <Redirect to={`/event/${eventId}`} />;
            } else if (targetPageName === "eventcheckout") {
              return <Redirect to={`/eventcheckout/${eventId}`} />;
            }
          } else {
            return <EventsPage />;
          }
        }}
      />
      <Route path="/event/:id" component={EventDetailPage} />
      <Route path="/eventcheckout/:id" component={EventCheckoutPage} />
    </Switch>
  );
};

export default Navigation;
