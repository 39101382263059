import { Table as TableInterface } from "../../../types/tickets";
import { useWindowDimensions } from "../../../hooks/getWindowDimensions";
import { StyleTable } from "./styles";

interface TableProps {
  item?: TableInterface;
}

const Table: React.FC<TableProps> = ({ item }) => {
  const { width } = useWindowDimensions();
  // console.log(width, "width");

  return (
    <img
      src={item.PICTURE}
      style={StyleTable(width, item)}
      alt={`table number ${item.ID}`}
    />
  );
};

export default Table;
