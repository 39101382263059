import { Restaurant } from './../../types/restaurants';
export enum ActionType {
  GET_RESTAURANT_REQUEST = "GET_RESTAURANT_REQUEST",
  GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_ERROR = "GET_RESTAURANT_ERROR",
}

export const endpoint = "getRestaurantsList";
export interface GetRestaurantsReq {
  type: ActionType.GET_RESTAURANT_REQUEST;
}
export interface GetRestaurantsSuccess {
  type: ActionType.GET_RESTAURANT_SUCCESS;
  payload: Restaurant[];
}
export interface GetRestaurantsError {
  type: ActionType.GET_RESTAURANT_ERROR;
  payload: any;
}

export type Action =
  | GetRestaurantsError
  | GetRestaurantsSuccess
  | GetRestaurantsReq;
