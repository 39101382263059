import React from "react";
// import { Link } from "react-router-dom";
import "./styles.scss";

const MainHeader: React.FC = () => {
  return (
    <header className="main-header">
      <div className="container">
        <a
          href="https://publifegroup.ru/"
          className="main-header__logo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/logo.svg" alt="" />
        </a>
        {/* <div className="main-header__menu">
          <Link className="main-header__menu__link" to="/">
            Проекты
          </Link>
          <Link className="main-header__menu__link" to="/blog">
            События
          </Link>
          <Link className="main-header__menu__link" to="/blog">
            О Холдинге
          </Link>
          <Link className="main-header__menu__link" to="/blog">
            Партнеры
          </Link>
          <Link className="main-header__menu__link" to="/blog">
            Контакты
          </Link>
        </div> */}
      </div>
    </header>
  );
};

export default MainHeader;
