import { Place } from "../../types/tickets";
import { ActionTypes, Action } from "./types";

export const AddItemToBasket = (item: Place): Action => ({
  type: ActionTypes.ADD_ITEM_TO_BASKET,
  payload: item,
});

export const removeBasketItem = (item: Place): Action => ({
  type: ActionTypes.REMOVE_ITEM_FROM_BASKET,
  payload: item,
});
