import "./styles.scss";
interface TextInputProps {
  title: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  isError: boolean;
  error: string;
}

const TextInput: React.FC<TextInputProps> = ({
  title,
  placeholder,
  value,
  onChange,
  onBlur,
  type,
  name,
  isError,
  error,
}) => {
  return (
    <div className="text-input__container">
      <div className="text-input__title">{title}</div>
      <input
        className="text-input__input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        name={name}
      />
      {isError && <div className="text-input__error"> {error}</div>}
    </div>
  );
};

export default TextInput;
