import React from "react";

export function CartSvg() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9499 16.6568L16.657 15.9497L11.7072 10.9999L16.6569 6.05023L15.9498 5.34313L11.0001 10.2928L6.05044 5.34311L5.34333 6.05022L10.293 10.9999L5.34324 15.9497L6.05035 16.6568L11.0001 11.707L15.9499 16.6568Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
