import React, { useState } from "react";
import { Map, Option, Ticket } from "../../types/tickets";
import Table from "./Tables";
import Seats from "./Seats";
import Row from "./Row";
import "./styles.scss";

interface EventMapProps {
  map: Map;
  tickets: Ticket[];
  options: Option[];
}

const EventMap: React.FC<EventMapProps> = ({ map, tickets, options }) => {
  const [filterOption, setFilterOption] = useState<string | null>(null);

  console.log(tickets, "tickets");

  return (
    <div className="event-map__main">
      <div className="event-map__filter">
        <div className="event-map__row-title">Билеты:</div>
        <Row title="Недоступно" color="#C7C7C7" />
        {options?.map((option, i) => (
          <Row
            key={i}
            color={option.COLOR}
            title={option.PRICE}
            inverted
            setFilterOption={setFilterOption}
          />
        ))}
      </div>
      <div
        style={{
          backgroundImage: `url(${map?.BACKGROUND_PICTURE})`,
        }}
        className="event-map__background"
      >
        <div style={{ position: "absolute" }}>
          {tickets?.map((table, index) => {
            let item = table.params;
            return (
              <div key={index}>
                <Table item={item} />
                {Object.values(table?.places).map((item, i) => (
                  <Seats
                    key={i}
                    seat={item}
                    tickets={tickets}
                    index={index}
                    filterOption={filterOption}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EventMap;
