export const returnImage = (type: string): string => {
  if (type === "Живая музыка") {
    return "https://i.ibb.co/PFFWvcX/concert.png";
  } else if (type === "Спортивная трансляция") {
    return "https://i.ibb.co/Fw3fz5t/ball.png";
  } else if (type === "Театральное представление") {
    return "https://i.ibb.co/Jmx1b0x/theatre.png";
  } else {
    return "";
  }
};
