import { axios } from "../../service";

import { Dispatch } from "redux";
import { Action, ActionType, endpoint, EventsType } from "./types";


export const getEvents = (id: number) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionType.GET_EVENTS_REQUEST });
  try {
    const { data } = await axios.get(`${endpoint}/${id}`);
    dispatch({
      type: ActionType.GET_EVENTS_SUCCESS,
      payload: Object.values(data?.events),
    });
  } catch (error) {
    dispatch({ type: ActionType.GET_EVENTS_ERROR, payload: error });
  }
};

export const filterEventsByType = (type: EventsType) : Action => {
  return {
    type: ActionType.FILTER_EVENTS_BY_TYPE,
    payload: type,
  };
};

export const filterEventsByCalender = (date: number): Action => {
  return {
    type: ActionType.FILTER_EVENTS_BY_CALENDER,
    payload: date,
  };
};

export const filterEventsByRestaurants = (id: string) : Action => {
  return {
    type: ActionType.FILTER_EVENTS_BY_RESTAURANTS,
    payload: id,
  };
};
