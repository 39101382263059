import React from "react";
import Loading from "../../../commons/Loading";
import EventCart from "../../../components/EventCart";
import EventMap from "../../../components/EventMap";
import { Map, Option, Ticket } from "../../../types/tickets";

interface RenderEventProps {
  ticketError: boolean;
  ticketLoading: boolean;
  map: Map;
  tickets: Ticket[];
  options: Option[];
}

export const RenderEventMap: React.FC<RenderEventProps> = ({
  ticketError,
  ticketLoading,
  map,
  tickets,
  options,
}) => {
  console.log(tickets, "tickets");
  if (ticketError) {
    return <div>Error</div>;
  } else if (ticketLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <EventMap map={map} tickets={tickets} options={options} />
        <EventCart />
      </>
    );
  }
};
