import { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { event } from "../../../types/events";
import moment from "moment";

interface ResponsiveHeaderProps {
  event?: event;
}
const ResponsiveHeader: React.FC<ResponsiveHeaderProps> = ({ event }) => {
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  return (
    <>
      <div className="responsive_event_checkout_header">
        <div className="responsive_event_checkout_header__icon_container">
          {!displayMenu ? (
            <MenuIcon onClick={() => setDisplayMenu(true)} />
          ) : (
            <CloseIcon onClick={() => setDisplayMenu(false)} />
          )}
        </div>
      </div>
      {displayMenu && <Menu event={event} />}
    </>
  );
};

export default ResponsiveHeader;

const Menu: React.FC<ResponsiveHeaderProps> = ({ event }) => {
  return (
    <div className="menu_event_checkout">
      <div className="menu_event_checkout_item1">
        <img src={event.RESTAURANT_LOGO} alt="RESTAURANT_LOGO" />
        <p className="menu_event_checkout_item1_text">{event.NAME}</p>
      </div>
      <div className="menu_event_checkout_item1">
        <div className="checkout-header__menu__place" style={{ marginLeft: 0 }}>
          <div
            className="checkout-header__menu__place__ico"
            style={{ marginLeft: 0 }}
          >
            <img src={event.RESTAURANT_LOGO} alt="RESTAURANT_LOGO" />
          </div>
          <div className="checkout-header__menu__place__info">
            <div className="checkout-header__menu__place__info__key">
              Место проведения:
            </div>
            <div className="checkout-header__menu__place__info__val">
              {event.PLACE}
            </div>
            <div className="checkout-header__menu__place__info__subval">
              {event.ADDRESS}
            </div>
          </div>
        </div>
        <div
          className="checkout-header__menu__date"
          style={{ marginLeft: 90, marginTop: 30, marginBottom: 30 }}
        >
          <div className="checkout-header__menu__date__ico">
            <div className="checkout-header__menu__date__ico__day">
              {moment(event.EVENT_DATE, "DD/MM/YYYY HH:mm").format("DD")}
            </div>
            <div className="checkout-header__menu__date__ico__mounth">
              {moment(event.EVENT_DATE, "DD/MM/YYYY HH:mm")
                .format("MMM")
                .slice(0, 3)}
            </div>
          </div>

          <div className="checkout-header__menu__date__info">
            <div className="checkout-header__menu__date__info__key">
              Время проведения:
            </div>
            <div className="checkout-header__menu__date__info__val">
              {moment(event.EVENT_DATE, "DD/MM/YYYY HH:mm").format("LLLL")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
