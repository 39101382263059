import React from "react";
import Header from "./Header/Header";
import ResponsiveHeader from "./Header/ResponsiveHeader";
import { useWindowDimensions } from "../../hooks/getWindowDimensions";
import { event } from "../../types/events";

interface EventCheckoutHeaderProps {
  Event?: event;
}
const EventCheckoutHeader: React.FC<EventCheckoutHeaderProps> = ({ Event }) => {
  const { width } = useWindowDimensions();

  if (width > 1200) {
    return <Header Event={Event} />;
  } else {
    return <ResponsiveHeader event={Event} />;
  }
};

export default EventCheckoutHeader;
