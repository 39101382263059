import { formatDate } from "./../../utils/formatDate/index";

import { event } from "../../types/events";
import { Action, ActionType } from "./types";
const initialState = {
  events: [],
  loading: false,
  error: null,
  eventsCopy: [],
  // updated: 0
};

interface EventState {
  error: string | null;
  events: event[];
  eventsCopy: event[];
  loading: boolean;
  // updated? : number
}

const eventReducer = (
  state: EventState = initialState,
  action: Action
): EventState => {
  switch (action.type) {
    case ActionType.GET_EVENTS_REQUEST:
      return {
        loading: true,
        error: null,
        events: [],
        eventsCopy: [],
      };
    case ActionType.GET_EVENTS_SUCCESS:
      return {
        loading: false,
        events: action.payload,
        eventsCopy: action.payload,
        error: null,
      };
    case ActionType.GET_EVENTS_ERROR:
      return {
        loading: false,
        events: [],
        eventsCopy: [],
        error: action.payload,
      };
    case ActionType.FILTER_EVENTS_BY_TYPE:
      return {
        ...state,
        events:
          state.eventsCopy.filter((event) => event.TYPE === action.payload) ||
          [],
      };
    case ActionType.FILTER_EVENTS_BY_CALENDER:
      return {
        ...state,
        events: compareDate(state.eventsCopy, action.payload),
      };
    case ActionType.FILTER_EVENTS_BY_RESTAURANTS:
      return {
        ...state,
        events: state.eventsCopy.filter(
          (event) => event.RESTAURANT_ID === action.payload
        ),
      };
    case ActionType.MULTIPLE_FILTER:
      return {
        ...state,
        events: filtersValue(state.eventsCopy),
      };
    default:
      return state;
  }
};

export default eventReducer;

const compareDate = (events: event[], date: number): event[] => {
  return events.filter((event) => {
    return formatDate(event.EVENT_DATE) >= date;
  });
};

const filtersValue = (events: event[]): event[] => {
  console.log(444, "filters");
  return events;
};

// 2021 10 05 2021 10 14
