import React from "react";
import "./styles.scss";
import EventItem from "../EventItem";
import { event } from "../../types/events";

interface EventListProps {
  events?: event[] | undefined;
}
const EventList: React.FC<EventListProps> = ({ events }) => {
  return (
    <div className={"event-list"}>
      {(events && events.length > 0) ?
        events.map((event: event, index: number) => (
          <EventItem key={index} event={event} />
        )) : <div className={"event-list-nothing"}>ничего не найдено</div>}
    </div>
  );
};

export default EventList;
