import { MainBanner } from '../../types/main-banner';
import { ActionType, Action } from './types';

const initialState = {
    banner: [],
    loading: false,
    error: null,
};

interface MainBannerState {
    banner: [] | MainBanner[];
    loading: boolean;
    error: any;
}

const mainBannerReducer = (state: MainBannerState = initialState, action: Action): MainBannerState => {
    switch (action.type) {
        case ActionType.GET_BANNER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ActionType.GET_BANNER_SUCCESS:
            return {
                banner: action.payload,
                loading: false,
                error: null,
            };
        case ActionType.GET_BANNER_ERROR:
            return {
                banner: [],
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default mainBannerReducer;
