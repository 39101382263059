import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";

const BackNav: React.FC = () => {
  const history = useHistory();
  const handleGoBack = (): void => history.goBack();

  return (
    <button className="back-nav" onClick={handleGoBack}>
      <div className="back-nav__str">
        <img src="/images/backstr.png" alt="" />
      </div>
      <div className="back-nav__text">Назад к событиям</div>
    </button>
  );
};

export default BackNav;
