import React, { useState } from "react";
// TYPES
import { FilterItem, DisplayFilter, EventsType, FilterEvents } from "./types";
// RENDER && STYLES
import { Filter } from "./Filter";
import { BarSvg, CalenderSvg, EventsTypeSvg } from "../../Assets";
import { Bars } from "./FilterItems/Bar";
import { useDispatch } from "react-redux";
import { Events } from "./FilterItems/Events";
import { Calender } from "./FilterItems/Calender";
import { convertDateToNumber } from "../../utils/formatDate";
import moment from "moment";
import {
  filterEventsByCalender,
  filterEventsByRestaurants,
  filterEventsByType,
} from "../../redux/events/eventsAction";
import "./styles.scss";

const MainFilter: React.FC = () => {
  const [filterType, setFilterType] = useState<null | DisplayFilter>(null);
  const [value, setValue] = useState(new Date());
  const dispatch = useDispatch();

  const handleChange = (date: Date): void => {
    setValue(date);
    dispatch(
      filterEventsByCalender(
        convertDateToNumber(moment(date).format("YYYY-MM-DD"))
      )
    );
  };

  const handleFilterBars = (id: string): void => {
    dispatch(filterEventsByRestaurants(id));
  };

  const handleFilterByEvent = (type: EventsType): void => {
    dispatch(filterEventsByType(type));
  };

  const filterList: FilterItem[] = [
    {
      id: "1",
      title: "Выбрать дату",
      type: DisplayFilter.CALENDER_FILTER,
      Icon: CalenderSvg,
      filterNode: (
        <Calender
          value={value}
          filterType={filterType}
          setValue={handleChange}
          // calendarBtn={calendarBtn}
          // handleFilterCalenderBtn={handleFilterCalenderBtn}
          // activeCalenderBtn={activeCalenderBtn}
        />
      ),
    },
    {
      id: "2",
      title: "Выбрать паб",
      type: DisplayFilter.RESTAURANTS_FILTER,
      Icon: BarSvg,
      filterNode: (
        <Bars handleFilterBars={handleFilterBars} filterType={filterType} />
      ),
    },
    {
      id: "3",
      title: "Тип события",
      type: DisplayFilter.EVENTS_FILTER,
      Icon: EventsTypeSvg,
      filterNode: (
        <Events
          filterEvents={filterEvents}
          filterType={filterType}
          handleFilterByEvent={handleFilterByEvent}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="main-filter">
        {filterList.map((item) => (
          <Filter
            key={item.id}
            title={item.title}
            Icon={item.Icon}
            type={item.type}
            filterNode={item.filterNode}
            filterType={filterType}
            setFilterType={setFilterType}
          />
        ))}
      </div>
    </div>
  );
};

export default MainFilter;

const filterEvents: FilterEvents[] = [
  {
    id: "1",
    type: "Живая музыка",
  },
  {
    id: "2",
    type: "Спортивная трансляция",
  },
  {
    id: "3",
    type: "Театральное представление",
  },
];
