import { MainBanner } from '../../types/main-banner';
export enum ActionType {
    GET_BANNER_REQUEST = 'GET_BANNER_REQUEST',
    GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS',
    GET_BANNER_ERROR = 'GET_BANNER_ERROR',
}

export const endpoint = 'getMainBanner';
export interface GetBannerReq {
    type: ActionType.GET_BANNER_REQUEST;
}
export interface GetBannerSuccess {
    type: ActionType.GET_BANNER_SUCCESS;
    payload: MainBanner[];
}
export interface GetBannerError {
    type: ActionType.GET_BANNER_ERROR;
    payload: any;
}

export type Action = GetBannerError | GetBannerSuccess | GetBannerReq;
