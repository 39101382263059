import axiosPackage from "axios";

export const server = {
  // development: "/mobileapp/web/",
  development: "https://app.publifegroup.ru/mobileapp/web/",
};

export const baseURL = server.development;

export const axios = axiosPackage.create({
  headers: {
    accept: "application/json",
    "content-type": "application/json; charset=utf-8",
  },
  baseURL,
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log("axios error", error, error.response);
    return Promise.reject(error.response);
  }
);
