import React from "react";

export function BarSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2948 9.2592H16.7918V8.84573C17.607 8.23564 18.1367 7.25704 18.1367 6.15562C18.1367 4.52388 16.9746 3.16113 15.4429 2.86792C14.9977 1.20685 13.4976 4.88107e-05 11.7255 0C10.7447 4.88107e-05 9.80336 0.383262 9.09568 1.05007C8.84299 0.983293 8.5817 0.94893 8.31905 0.94893C7.07038 0.94893 5.98112 1.71443 5.50656 2.81521C5.44183 2.81135 5.37676 2.80945 5.31155 2.80945C3.48557 2.80945 2 4.31053 2 6.15557C2 7.17147 2.45094 8.08238 3.16051 8.69656V22.5357C3.16051 23.3411 3.81265 24 4.6097 24H15.3426C16.1397 24 16.7918 23.3411 16.7918 22.5357V20.388H19.2948C20.7864 20.388 22 19.1618 22 17.6546V11.9926C22 10.4854 20.7864 9.2592 19.2948 9.2592ZM14.8251 7.64694H6.34559V10.6269C6.34559 11.1391 5.93465 11.5543 5.42777 11.5543C4.92089 11.5543 4.50995 11.1391 4.50995 10.6269V7.40679C4.10452 7.14067 3.83564 6.67941 3.83564 6.15557C3.83564 5.33326 4.49773 4.66426 5.31155 4.66426C5.49472 4.66426 5.67399 4.69813 5.84446 4.7649C6.11672 4.87156 6.42298 4.841 6.66929 4.68246C6.91561 4.52393 7.07256 4.25644 7.09202 3.96207C7.13497 3.31254 7.67392 2.80374 8.31905 2.80374C8.52948 2.80374 8.72893 2.85548 8.91177 2.95744C9.31634 3.18305 9.8238 3.06956 10.0964 2.69255C10.4813 2.16017 11.0751 1.85481 11.7255 1.85481C12.8016 1.85481 13.686 2.70451 13.739 3.78923C13.7511 4.03816 13.8619 4.27172 14.0465 4.43714C14.2309 4.60261 14.4736 4.68627 14.7197 4.6687C14.7624 4.66567 14.7959 4.66431 14.8251 4.66431C15.6389 4.66431 16.301 5.33331 16.301 6.15562C16.301 6.97794 15.6389 7.64694 14.8251 7.64694ZM19.4881 17.6547C19.4881 17.7587 19.3978 17.8499 19.2948 17.8499H16.7918V11.7974H19.2948C19.3978 11.7974 19.4881 11.8886 19.4881 11.9926V17.6547Z"
        fill="white"
      />
    </svg>
  );
}
