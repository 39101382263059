import { Restaurant } from "./../../types/restaurants";
import { ActionType, Action } from "./types";

const initialState = {
  restaurants: [],
  loading: false,
  error: null,
};

interface RestaurantsState {
  restaurants: [] | Restaurant[];
  loading: boolean;
  error: any;
}

const restaurantsReducer = (
  state: RestaurantsState = initialState,
  action: Action
): RestaurantsState => {
  switch (action.type) {
    case ActionType.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_RESTAURANT_SUCCESS:
      return {
        restaurants: action.payload,
        loading: false,
        error: null,
      };
    case ActionType.GET_RESTAURANT_ERROR:
      return {
        restaurants: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default restaurantsReducer;
