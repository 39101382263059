import { ActionType, Action, endpoint } from './types';
import { Dispatch } from 'redux';
import { axios } from 'src/service';

export const getMainBanner = () => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.GET_BANNER_REQUEST });
    try {
        const { data } = await axios.get(endpoint);
        dispatch({
            type: ActionType.GET_BANNER_SUCCESS,
            payload: data.events,
        });
    } catch (error) {
        dispatch({ type: ActionType.GET_BANNER_ERROR, payload: error });
    }
};
