interface ErrorProps {
  msg?: string;
}

let src =
  "https://www.seekpng.com/png/detail/334-3345964_error-icon-download-attention-symbol.png";

const Error: React.FC<ErrorProps> = ({
  msg = "Error Fetching the Data From the server",
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: 50,
    }}
  >
    <img
      src={src}
      alt="Error"
      style={{ display: "block", width: 100, height: 100 }}
    />
    <h2 style={{ marginTop: 20, textAlign: "center", color: "red" }}>{msg}</h2>
  </div>
);

export default Error;
