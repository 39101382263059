import React, { useEffect } from "react";
//Hooks
import { useParams } from "react-router";
//REDUX
import { getEventById } from "../../redux/eventDetail/eventDetail-action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/root-reducer";
//TYPES
import { RouteParams } from "../../types/routes";
//RENDER
import BackNav from "../../components/BackNav";
import MainHeader from "../../components/Mainheader";
import EventDetail from "../../components/EventDetail";
import Loading from "../../commons/Loading";
import Error from "../../commons/Error";

const EventDetailPage: React.FC = () => {
  const prams = useParams<RouteParams>();
  const { event, loading, error } = useSelector(
    (state: RootState) => state.event
  );
  let id = prams.id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getEventById(id));
    }
  }, [id, dispatch]);

  if (loading) return <Loading />;
  return (
    <div className="event-detail-page">
      <MainHeader />
      <div className="container">
        <BackNav />
        {error ? <Error /> : <EventDetail event={event} />}
      </div>
    </div>
  );
};

export default EventDetailPage;
