import { GetTickets, GetTicketsResponse } from './../../types/tickets';
import { axios } from "../axios";
import { event } from "../../types/events";
import { endpoints } from "./types";
import { TicketPayment } from "../../types/tickets";
// type GreetFunction = (id: string) => event;



export interface Event {
  [key : string]:event;
}

export interface GetEventsResponse {
  events:Event
}


 const getEventById = async (id: number) : Promise<event[]> => {
  const res =  await axios.get<GetEventsResponse>(`${endpoints.events.event}/${id}`);
  return  Object.values(res.data.events);
  // return Object.values(data.event)[0];
};


const getEventTickets = async (id: string) : Promise<GetTickets>=> {
  const { data } = await axios.get<GetTicketsResponse>(`${endpoints.events.eventTicket}/${id}`);
  let map = data?.map;
  let eventTickets = Object.values(data?.tables);
  let options = Object.values(data?.types);
  let obj = {
    map,
    eventTickets,
    options,
  };
  return obj;
};

const buyTicket = async (value: TicketPayment): Promise<string> => {
  const { data } = await axios.post(endpoints.tickets.buyTicket, value);
  return data.orderId;
};

const payTicket = async (id: string): Promise<string> => {
  const { data } = await axios.post(endpoints.tickets.payTicket, { id });
  return data.sber_link;
};

export { getEventTickets, buyTicket, payTicket, getEventById,  };

