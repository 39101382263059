import React from "react";
import ReactLoading, { LoadingType } from "react-loading";

interface LoadingProps {
  type?: LoadingType;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({
  type = "spokes",
  color = "#000",
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100%",
    }}
  >
    <ReactLoading type={type} color={color} height={50} width={50} />
  </div>
);

export default Loading;
