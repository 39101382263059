import React from "react";
import { useDispatch } from "react-redux";
import { removeBasketItem } from "../../redux/tickets/ticketsAction";
import { Place } from "../../types/tickets";
import { CartSvg } from "../../Assets";
import "./style.scss";
interface EventCartItemProps {
  product: Place;
}
const EventCartItem: React.FC<EventCartItemProps> = ({ product }) => {
  const dispatch = useDispatch();

  const handleRemove = (item: Place): void => {
    dispatch(removeBasketItem(item));
  };

  return (
    <li className="event-cart__content__list__item">
      <div className="event-cart__content__list__item__type">
        {/* <div className="event-cart__content__list__item__type__color"></div> */}
        <div
          className="eventCart"
          style={{
            backgroundColor: product.COLOR,
          }}
        />
      </div>
      <div className="event-cart__content__list__item__meta">
        <div className="event-cart__content__list__item__meta__name">
          {product.NAME}
        </div>
        <div className="event-cart__content__list__item__meta__price">
          {product.PRICE} ₽
        </div>
      </div>

      <button
        onClick={() => handleRemove(product)}
        className="event-cart__content__list__item__del"
      >
        <CartSvg />
      </button>
    </li>
  );
};

export default EventCartItem;
