import React from "react";

export function LiveMusicSvg() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="-2.03512" y="-1.79114" width="27.6073" height="26.0053" fill="black">
<rect fill="white" x="-2.03512" y="-1.79114" width="27.6073" height="26.0053"/>
<path d="M12.8672 3.00456L12.8451 3.00266C12.624 2.98391 12.4296 3.14794 12.4108 3.36902C12.3921 3.59011 12.5561 3.78453 12.7772 3.80327L12.7971 3.80499L12.8171 3.80676C13.038 3.82669 13.2333 3.66371 13.2533 3.44273C13.2732 3.22175 13.1102 3.02645 12.8892 3.00652L12.8672 3.00456Z"/>
<path d="M14.4053 3.28946C14.192 3.22818 13.9695 3.35137 13.9082 3.56461C13.8469 3.77786 13.9701 4.00041 14.1834 4.06169L14.2214 4.07274C14.4343 4.13519 14.6576 4.01322 14.72 3.80031C14.7824 3.5874 14.6605 3.36419 14.4476 3.30174L14.4053 3.28946Z"/>
<path d="M11.3874 3.81708C11.608 3.79375 11.768 3.59598 11.7447 3.37533C11.7214 3.15468 11.5236 2.99472 11.3029 3.01804L11.2592 3.02279C11.0387 3.04732 10.8798 3.24596 10.9043 3.46648C10.9288 3.687 11.1275 3.84588 11.348 3.82135L11.3874 3.81708Z"/>
<path d="M9.98886 4.10666C10.2006 4.04044 10.3186 3.81508 10.2524 3.60332C10.1862 3.39156 9.9608 3.27358 9.74904 3.3398C9.73501 3.34419 9.721 3.34861 9.707 3.35307C9.49561 3.42046 9.37886 3.64645 9.44624 3.85785C9.51363 4.06925 9.73962 4.18599 9.95102 4.11861L9.98886 4.10666Z"/>
<path d="M15.8797 3.87617C15.6827 3.77417 15.4402 3.85123 15.3383 4.04828C15.2363 4.24532 15.3133 4.48774 15.5104 4.58973L15.5456 4.60807C15.742 4.71114 15.9849 4.63541 16.0879 4.43892C16.191 4.24244 16.1153 3.9996 15.9188 3.89654C15.9058 3.88971 15.8928 3.88292 15.8797 3.87617Z"/>
<path d="M8.67397 4.66518C8.86869 4.55882 8.94031 4.31474 8.83395 4.12002C8.72758 3.9253 8.4835 3.85368 8.28878 3.96004L8.25014 3.98129C8.05601 4.08871 7.98571 4.33318 8.09313 4.52731C8.20056 4.72145 8.44502 4.79175 8.63916 4.68432L8.67397 4.66518Z"/>
<path d="M17.2101 4.74056C17.0368 4.60199 16.784 4.63015 16.6454 4.80344C16.5069 4.97673 16.535 5.22953 16.7083 5.3681L16.7393 5.39299C16.9118 5.53249 17.1647 5.50572 17.3043 5.33318C17.4438 5.16065 17.417 4.90769 17.2444 4.76819L17.2101 4.74056Z"/>
<path d="M7.49258 5.46956C7.66293 5.3274 7.68579 5.07406 7.54363 4.90371C7.40147 4.73335 7.14813 4.7105 6.97778 4.85266L6.94398 4.88102C6.7744 5.02409 6.75292 5.27756 6.896 5.44714C7.03908 5.61672 7.29254 5.6382 7.46212 5.49512L7.49258 5.46956Z"/>
<path d="M18.3462 5.84726C18.2031 5.67768 17.9496 5.6562 17.78 5.79928C17.6105 5.94236 17.589 6.19582 17.7321 6.3654L17.7576 6.39586C17.8998 6.56621 18.1531 6.58907 18.3235 6.44691C18.4938 6.30475 18.5167 6.05141 18.3745 5.88106L18.3462 5.84726Z"/>
<path d="M6.48971 6.48792C6.62921 6.31539 6.60244 6.06243 6.4299 5.92293C6.25737 5.78343 6.00441 5.8102 5.86491 5.98274L5.83728 6.0171C5.69871 6.19039 5.72687 6.4432 5.90016 6.58176C6.07345 6.72033 6.32625 6.69217 6.46482 6.51888L6.48971 6.48792Z"/>
<path d="M5.70479 7.68162C5.80785 7.48514 5.73213 7.2423 5.53564 7.13923C5.33916 7.03616 5.09632 7.11189 4.99326 7.30838C4.98643 7.32139 4.97964 7.33442 4.97288 7.34747C4.87089 7.54452 4.94795 7.78693 5.145 7.88892C5.34204 7.99091 5.58446 7.91386 5.68645 7.71681L5.70479 7.68162Z"/>
<path d="M5.16946 9.00576C5.23191 8.79285 5.10994 8.56963 4.89703 8.50718C4.68412 8.44473 4.46091 8.56671 4.39846 8.77961L4.38618 8.82189C4.3249 9.03513 4.44809 9.25768 4.66133 9.31897C4.87458 9.38025 5.09713 9.25706 5.15841 9.04381L5.16946 9.00576Z"/>
<path d="M19.9377 13.3086C19.999 13.0953 19.8758 12.8728 19.6626 12.8115C19.4493 12.7502 19.2268 12.8734 19.1655 13.0867L19.1544 13.1247C19.092 13.3376 19.214 13.5608 19.4269 13.6233C19.6398 13.6857 19.863 13.5638 19.9254 13.3509L19.9377 13.3086Z"/>
<path d="M19.351 14.783C19.453 14.5859 19.3759 14.3435 19.1789 14.2415C18.9819 14.1396 18.7394 14.2166 18.6375 14.4137L18.6191 14.4488C18.516 14.6453 18.5918 14.8882 18.7883 14.9912C18.9847 15.0943 19.2276 15.0186 19.3306 14.8221L19.351 14.783Z"/>
<path d="M5.77702 14.9843L5.17735 15.0954C5.30074 15.1948 5.47685 15.2155 5.62403 15.1341C5.69006 15.0975 5.74176 15.0451 5.77702 14.9843Z"/>
<path d="M18.4866 16.1134C18.6252 15.9401 18.597 15.6873 18.4237 15.5487C18.2505 15.4101 17.9976 15.4383 17.8591 15.6116L17.8342 15.6425C17.6947 15.8151 17.7215 16.068 17.894 16.2075C18.0665 16.347 18.3195 16.3203 18.459 16.1477L18.4866 16.1134Z"/>
<path d="M6.56628 15.7346C6.42412 15.5643 6.17078 15.5414 6.00043 15.6836C5.83007 15.8257 5.80722 16.0791 5.94938 16.2494L5.97774 16.2832C6.12082 16.4528 6.37428 16.4743 6.54385 16.3312C6.71344 16.1881 6.73492 15.9346 6.59184 15.7651L6.56628 15.7346Z"/>
<path d="M17.3799 17.2495C17.5495 17.1064 17.571 16.8529 17.4279 16.6833C17.2848 16.5137 17.0314 16.4923 16.8618 16.6353L16.8313 16.6609C16.661 16.8031 16.6381 17.0564 16.7803 17.2268C16.9224 17.3971 17.1758 17.42 17.3461 17.2778L17.3799 17.2495Z"/>
<path d="M7.58464 16.7375C7.41211 16.598 7.15915 16.6247 7.01965 16.7973C6.88015 16.9698 6.90692 17.2228 7.07946 17.3623L7.11382 17.3899C7.28711 17.5285 7.53992 17.5003 7.67848 17.327C7.81705 17.1537 7.78889 16.9009 7.6156 16.7624L7.58464 16.7375Z"/>
<path d="M16.0738 18.1492C16.2679 18.0418 16.3382 17.7973 16.2308 17.6032C16.1233 17.409 15.8789 17.3387 15.6847 17.4461L15.6499 17.4653C15.4552 17.5717 15.3836 17.8157 15.49 18.0104C15.5963 18.2052 15.8404 18.2768 16.0351 18.1704L16.0738 18.1492Z"/>
<path d="M8.77834 17.5224C8.58186 17.4193 8.33902 17.4951 8.23595 17.6915C8.13288 17.888 8.20861 18.1309 8.4051 18.2339L8.44419 18.2543C8.64124 18.3563 8.88365 18.2792 8.98564 18.0822C9.08763 17.8851 9.01057 17.6427 8.81353 17.5407L8.77834 17.5224Z"/>
<path d="M14.6169 18.7774C14.8283 18.71 14.945 18.484 14.8777 18.2726C14.8103 18.0612 14.5843 17.9445 14.3729 18.0119L14.335 18.0238C14.1233 18.09 14.0053 18.3154 14.0715 18.5271C14.1377 18.7389 14.3631 18.8569 14.5749 18.7907C14.5889 18.7863 14.6029 18.7819 14.6169 18.7774Z"/>
<path d="M10.1025 18.0577C9.88957 17.9953 9.66635 18.1172 9.6039 18.3302C9.54145 18.5431 9.66343 18.7663 9.87633 18.8287L9.91861 18.841C10.1319 18.9023 10.3544 18.7791 10.4157 18.5659C10.477 18.3526 10.3538 18.1301 10.1405 18.0688L10.1025 18.0577Z"/>
<path d="M13.0647 19.1077C13.2852 19.0831 13.4441 18.8845 13.4196 18.664C13.3951 18.4435 13.1964 18.2846 12.9759 18.3091L12.9365 18.3134C12.7159 18.3367 12.5559 18.5345 12.5792 18.7551C12.6025 18.9758 12.8003 19.1357 13.021 19.1124L13.0647 19.1077Z"/>
<path d="M11.5068 18.3237C11.2859 18.3038 11.0906 18.4668 11.0706 18.6877C11.0507 18.9087 11.2137 19.104 11.4347 19.1239L11.4567 19.1259L11.4788 19.1278C11.6999 19.1466 11.8943 18.9825 11.9131 18.7614C11.9318 18.5404 11.7678 18.3459 11.5467 18.3272L11.5268 18.3255L11.5068 18.3237Z"/>
<path d="M22.0285 12.1567L20.7829 12.3875C20.0955 16.8968 16.0115 20.1551 11.4033 19.7519C8.44458 19.4931 5.96072 17.7852 4.58976 15.3887L3.34338 15.6197C4.85432 18.5388 7.7816 20.6449 11.2983 20.9525C16.6832 21.4237 21.4394 17.5021 22.0285 12.1567Z"/>
<path d="M20.8416 6.24616L19.5813 6.47974C18.1827 4.22111 15.7734 2.62735 12.9234 2.37801C8.41795 1.98384 4.41356 5.08961 3.59479 9.44266L2.33539 9.67608C3.06472 4.48327 7.74376 0.715019 13.0284 1.17737C16.4382 1.47568 19.2938 3.4647 20.8416 6.24616Z"/>
<path d="M17.4155 13.0119C16.5614 15.32 14.2453 16.8685 11.6752 16.6436C10.1819 16.513 8.87702 15.8076 7.95934 14.7645L6.92486 14.9562C7.99841 16.4003 9.66098 17.3965 11.5946 17.5656C14.7762 17.844 17.6239 15.79 18.4496 12.8203L17.4155 13.0119Z"/>
<path d="M7.03795 8.80479C7.9785 6.67134 10.1994 5.27165 12.6514 5.48617C14.0237 5.60623 15.2369 6.21163 16.1366 7.11845L17.204 6.92063C16.1294 5.61384 14.5506 4.72326 12.7321 4.56416C9.66127 4.2955 6.9015 6.19954 5.97063 9.00261L7.03795 8.80479Z"/>
<path d="M0.230334 9.3184L22.3512 5.21854L22.5198 6.12858L0.398999 10.2284L0.230334 9.3184Z"/>
<path d="M1.42828 15.7819L23.5491 11.6821L23.7178 12.5921L1.59695 16.692L1.42828 15.7819Z"/>
<path d="M1.65168 11.4326L0.986685 11.5558L1.55057 14.5983L2.21557 14.475L1.98115 13.2102L2.16804 13.1756L3.06311 14.3179L3.84545 14.1729L2.67458 12.731L3.21202 11.1434L2.44706 11.2851L2.05898 12.6114L1.87643 12.6452L1.65168 11.4326Z"/>
<path d="M3.59964 12.645C3.80505 13.7533 4.32234 14.143 5.17423 13.9851C6.00873 13.8304 6.35766 13.3117 6.14661 12.173C5.94361 11.0777 5.51952 10.6573 4.58071 10.8313C3.71578 10.9916 3.39664 11.5497 3.59964 12.645ZM4.30021 12.5197C4.1689 11.8112 4.30666 11.5114 4.68479 11.4414C5.08031 11.3681 5.30829 11.5551 5.44765 12.307C5.5862 13.0546 5.43524 13.3073 5.07015 13.375C4.70505 13.4427 4.44037 13.2759 4.30021 12.5197Z"/>
<path d="M6.94108 13.5992L7.60608 13.4759L7.37488 12.2285L8.34412 12.0489L8.57532 13.2963L9.24031 13.1731L8.67643 10.1306L8.01143 10.2539L8.23135 11.4404L7.26211 11.6201L7.04219 10.4335L6.3772 10.5567L6.94108 13.5992Z"/>
<path d="M9.8995 13.0509L11.8206 12.6948L11.9253 13.2599L12.5077 13.1519L12.2902 11.9784L11.9816 12.0356L11.5305 9.60163L10.8655 9.72488L11.3166 12.1588L10.4517 12.3191L10.0006 9.88518L9.33561 10.0084L9.8995 13.0509Z"/>
<path d="M12.8664 12.501L14.6919 12.1627L14.5791 11.5542L13.4186 11.7693L13.2986 11.1217L14.3113 10.934L14.2058 10.3646L13.1931 10.5523L13.0803 9.94379L14.2364 9.72951L14.1236 9.12102L12.3025 9.45855L12.8664 12.501Z"/>
<path d="M15.2391 12.0613L15.9041 11.938L15.7438 11.0731C15.8358 11.0605 15.8975 11.0536 15.9888 11.0367C16.7624 10.8933 17.0354 10.474 16.8888 9.683C16.7454 8.90935 16.3959 8.67292 15.5874 8.82275C15.1789 8.89847 15.0343 8.94325 14.6768 9.02749L15.2391 12.0613ZM15.6358 10.4907L15.4465 9.46927C15.5354 9.43932 15.5867 9.4253 15.678 9.40839C16.0344 9.34233 16.1527 9.49573 16.218 9.84778C16.284 10.2042 16.2076 10.3982 15.8816 10.4586C15.7817 10.4771 15.7331 10.4816 15.6358 10.4907Z"/>
<path d="M18.2773 11.4982L18.9423 11.3749L18.4912 8.94094L19.204 8.80883L19.0912 8.20033L17.0006 8.5878L17.1134 9.1963L17.8262 9.06419L18.2773 11.4982Z"/>
<path d="M20.1077 11.1364C20.3706 11.1237 20.6346 11.0927 20.978 11.0291C21.7951 10.8776 21.9826 10.4338 21.8513 9.72533C21.7159 8.99514 21.3683 8.74487 20.7816 8.85362C20.6295 8.88181 20.5129 8.90791 20.3901 8.94865L20.2129 7.99245L19.5479 8.1157L20.1077 11.1364ZM20.6688 10.4525L20.4972 9.52672C20.5695 9.50433 20.6209 9.49032 20.6947 9.47662C20.986 9.42265 21.1072 9.49459 21.1773 9.87273C21.2433 10.2291 21.164 10.3832 20.8815 10.4356C20.8076 10.4493 20.7495 10.451 20.6688 10.4525ZM22.2849 10.7554L22.9499 10.6321L22.386 7.58968L21.7211 7.71293L22.2849 10.7554Z"/>
</mask>
<path d="M12.8672 3.00456L12.8451 3.00266C12.624 2.98391 12.4296 3.14794 12.4108 3.36902C12.3921 3.59011 12.5561 3.78453 12.7772 3.80327L12.7971 3.80499L12.8171 3.80676C13.038 3.82669 13.2333 3.66371 13.2533 3.44273C13.2732 3.22175 13.1102 3.02645 12.8892 3.00652L12.8672 3.00456Z" fill="#ffffff"/>
<path d="M14.4053 3.28946C14.192 3.22818 13.9695 3.35137 13.9082 3.56461C13.8469 3.77786 13.9701 4.00041 14.1834 4.06169L14.2214 4.07274C14.4343 4.13519 14.6576 4.01322 14.72 3.80031C14.7824 3.5874 14.6605 3.36419 14.4476 3.30174L14.4053 3.28946Z" fill="#ffffff"/>
<path d="M11.3874 3.81708C11.608 3.79375 11.768 3.59598 11.7447 3.37533C11.7214 3.15468 11.5236 2.99472 11.3029 3.01804L11.2592 3.02279C11.0387 3.04732 10.8798 3.24596 10.9043 3.46648C10.9288 3.687 11.1275 3.84588 11.348 3.82135L11.3874 3.81708Z" fill="#ffffff"/>
<path d="M9.98886 4.10666C10.2006 4.04044 10.3186 3.81508 10.2524 3.60332C10.1862 3.39156 9.9608 3.27358 9.74904 3.3398C9.73501 3.34419 9.721 3.34861 9.707 3.35307C9.49561 3.42046 9.37886 3.64645 9.44624 3.85785C9.51363 4.06925 9.73962 4.18599 9.95102 4.11861L9.98886 4.10666Z" fill="#ffffff"/>
<path d="M15.8797 3.87617C15.6827 3.77417 15.4402 3.85123 15.3383 4.04828C15.2363 4.24532 15.3133 4.48774 15.5104 4.58973L15.5456 4.60807C15.742 4.71114 15.9849 4.63541 16.0879 4.43892C16.191 4.24244 16.1153 3.9996 15.9188 3.89654C15.9058 3.88971 15.8928 3.88292 15.8797 3.87617Z" fill="#ffffff"/>
<path d="M8.67397 4.66518C8.86869 4.55882 8.94031 4.31474 8.83395 4.12002C8.72758 3.9253 8.4835 3.85368 8.28878 3.96004L8.25014 3.98129C8.05601 4.08871 7.98571 4.33318 8.09313 4.52731C8.20056 4.72145 8.44502 4.79175 8.63916 4.68432L8.67397 4.66518Z" fill="#ffffff"/>
<path d="M17.2101 4.74056C17.0368 4.60199 16.784 4.63015 16.6454 4.80344C16.5069 4.97673 16.535 5.22953 16.7083 5.3681L16.7393 5.39299C16.9118 5.53249 17.1647 5.50572 17.3043 5.33318C17.4438 5.16065 17.417 4.90769 17.2444 4.76819L17.2101 4.74056Z" fill="#ffffff"/>
<path d="M7.49258 5.46956C7.66293 5.3274 7.68579 5.07406 7.54363 4.90371C7.40147 4.73335 7.14813 4.7105 6.97778 4.85266L6.94398 4.88102C6.7744 5.02409 6.75292 5.27756 6.896 5.44714C7.03908 5.61672 7.29254 5.6382 7.46212 5.49512L7.49258 5.46956Z" fill="#ffffff"/>
<path d="M18.3462 5.84726C18.2031 5.67768 17.9496 5.6562 17.78 5.79928C17.6105 5.94236 17.589 6.19582 17.7321 6.3654L17.7576 6.39586C17.8998 6.56621 18.1531 6.58907 18.3235 6.44691C18.4938 6.30475 18.5167 6.05141 18.3745 5.88106L18.3462 5.84726Z" fill="#ffffff"/>
<path d="M6.48971 6.48792C6.62921 6.31539 6.60244 6.06243 6.4299 5.92293C6.25737 5.78343 6.00441 5.8102 5.86491 5.98274L5.83728 6.0171C5.69871 6.19039 5.72687 6.4432 5.90016 6.58176C6.07345 6.72033 6.32625 6.69217 6.46482 6.51888L6.48971 6.48792Z" fill="#ffffff"/>
<path d="M5.70479 7.68162C5.80785 7.48514 5.73213 7.2423 5.53564 7.13923C5.33916 7.03616 5.09632 7.11189 4.99326 7.30838C4.98643 7.32139 4.97964 7.33442 4.97288 7.34747C4.87089 7.54452 4.94795 7.78693 5.145 7.88892C5.34204 7.99091 5.58446 7.91386 5.68645 7.71681L5.70479 7.68162Z" fill="#ffffff"/>
<path d="M5.16946 9.00576C5.23191 8.79285 5.10994 8.56963 4.89703 8.50718C4.68412 8.44473 4.46091 8.56671 4.39846 8.77961L4.38618 8.82189C4.3249 9.03513 4.44809 9.25768 4.66133 9.31897C4.87458 9.38025 5.09713 9.25706 5.15841 9.04381L5.16946 9.00576Z" fill="#ffffff"/>
<path d="M19.9377 13.3086C19.999 13.0953 19.8758 12.8728 19.6626 12.8115C19.4493 12.7502 19.2268 12.8734 19.1655 13.0867L19.1544 13.1247C19.092 13.3376 19.214 13.5608 19.4269 13.6233C19.6398 13.6857 19.863 13.5638 19.9254 13.3509L19.9377 13.3086Z" fill="#ffffff"/>
<path d="M19.351 14.783C19.453 14.5859 19.3759 14.3435 19.1789 14.2415C18.9819 14.1396 18.7394 14.2166 18.6375 14.4137L18.6191 14.4488C18.516 14.6453 18.5918 14.8882 18.7883 14.9912C18.9847 15.0943 19.2276 15.0186 19.3306 14.8221L19.351 14.783Z" fill="#ffffff"/>
<path d="M5.77702 14.9843L5.17735 15.0954C5.30074 15.1948 5.47685 15.2155 5.62403 15.1341C5.69006 15.0975 5.74176 15.0451 5.77702 14.9843Z" fill="#ffffff"/>
<path d="M18.4866 16.1134C18.6252 15.9401 18.597 15.6873 18.4237 15.5487C18.2505 15.4101 17.9976 15.4383 17.8591 15.6116L17.8342 15.6425C17.6947 15.8151 17.7215 16.068 17.894 16.2075C18.0665 16.347 18.3195 16.3203 18.459 16.1477L18.4866 16.1134Z" fill="#ffffff"/>
<path d="M6.56628 15.7346C6.42412 15.5643 6.17078 15.5414 6.00043 15.6836C5.83007 15.8257 5.80722 16.0791 5.94938 16.2494L5.97774 16.2832C6.12082 16.4528 6.37428 16.4743 6.54385 16.3312C6.71344 16.1881 6.73492 15.9346 6.59184 15.7651L6.56628 15.7346Z" fill="#ffffff"/>
<path d="M17.3799 17.2495C17.5495 17.1064 17.571 16.8529 17.4279 16.6833C17.2848 16.5137 17.0314 16.4923 16.8618 16.6353L16.8313 16.6609C16.661 16.8031 16.6381 17.0564 16.7803 17.2268C16.9224 17.3971 17.1758 17.42 17.3461 17.2778L17.3799 17.2495Z" fill="#ffffff"/>
<path d="M7.58464 16.7375C7.41211 16.598 7.15915 16.6247 7.01965 16.7973C6.88015 16.9698 6.90692 17.2228 7.07946 17.3623L7.11382 17.3899C7.28711 17.5285 7.53992 17.5003 7.67848 17.327C7.81705 17.1537 7.78889 16.9009 7.6156 16.7624L7.58464 16.7375Z" fill="#ffffff"/>
<path d="M16.0738 18.1492C16.2679 18.0418 16.3382 17.7973 16.2308 17.6032C16.1233 17.409 15.8789 17.3387 15.6847 17.4461L15.6499 17.4653C15.4552 17.5717 15.3836 17.8157 15.49 18.0104C15.5963 18.2052 15.8404 18.2768 16.0351 18.1704L16.0738 18.1492Z" fill="#ffffff"/>
<path d="M8.77834 17.5224C8.58186 17.4193 8.33902 17.4951 8.23595 17.6915C8.13288 17.888 8.20861 18.1309 8.4051 18.2339L8.44419 18.2543C8.64124 18.3563 8.88365 18.2792 8.98564 18.0822C9.08763 17.8851 9.01057 17.6427 8.81353 17.5407L8.77834 17.5224Z" fill="#ffffff"/>
<path d="M14.6169 18.7774C14.8283 18.71 14.945 18.484 14.8777 18.2726C14.8103 18.0612 14.5843 17.9445 14.3729 18.0119L14.335 18.0238C14.1233 18.09 14.0053 18.3154 14.0715 18.5271C14.1377 18.7389 14.3631 18.8569 14.5749 18.7907C14.5889 18.7863 14.6029 18.7819 14.6169 18.7774Z" fill="#ffffff"/>
<path d="M10.1025 18.0577C9.88957 17.9953 9.66635 18.1172 9.6039 18.3302C9.54145 18.5431 9.66343 18.7663 9.87633 18.8287L9.91861 18.841C10.1319 18.9023 10.3544 18.7791 10.4157 18.5659C10.477 18.3526 10.3538 18.1301 10.1405 18.0688L10.1025 18.0577Z" fill="#ffffff"/>
<path d="M13.0647 19.1077C13.2852 19.0831 13.4441 18.8845 13.4196 18.664C13.3951 18.4435 13.1964 18.2846 12.9759 18.3091L12.9365 18.3134C12.7159 18.3367 12.5559 18.5345 12.5792 18.7551C12.6025 18.9758 12.8003 19.1357 13.021 19.1124L13.0647 19.1077Z" fill="#ffffff"/>
<path d="M11.5068 18.3237C11.2859 18.3038 11.0906 18.4668 11.0706 18.6877C11.0507 18.9087 11.2137 19.104 11.4347 19.1239L11.4567 19.1259L11.4788 19.1278C11.6999 19.1466 11.8943 18.9825 11.9131 18.7614C11.9318 18.5404 11.7678 18.3459 11.5467 18.3272L11.5268 18.3255L11.5068 18.3237Z" fill="#ffffff"/>
<path d="M22.0285 12.1567L20.7829 12.3875C20.0955 16.8968 16.0115 20.1551 11.4033 19.7519C8.44458 19.4931 5.96072 17.7852 4.58976 15.3887L3.34338 15.6197C4.85432 18.5388 7.7816 20.6449 11.2983 20.9525C16.6832 21.4237 21.4394 17.5021 22.0285 12.1567Z" fill="#ffffff"/>
<path d="M20.8416 6.24616L19.5813 6.47974C18.1827 4.22111 15.7734 2.62735 12.9234 2.37801C8.41795 1.98384 4.41356 5.08961 3.59479 9.44266L2.33539 9.67608C3.06472 4.48327 7.74376 0.715019 13.0284 1.17737C16.4382 1.47568 19.2938 3.4647 20.8416 6.24616Z" fill="#ffffff"/>
<path d="M17.4155 13.0119C16.5614 15.32 14.2453 16.8685 11.6752 16.6436C10.1819 16.513 8.87702 15.8076 7.95934 14.7645L6.92486 14.9562C7.99841 16.4003 9.66098 17.3965 11.5946 17.5656C14.7762 17.844 17.6239 15.79 18.4496 12.8203L17.4155 13.0119Z" fill="#ffffff"/>
<path d="M7.03795 8.80479C7.9785 6.67134 10.1994 5.27165 12.6514 5.48617C14.0237 5.60623 15.2369 6.21163 16.1366 7.11845L17.204 6.92063C16.1294 5.61384 14.5506 4.72326 12.7321 4.56416C9.66127 4.2955 6.9015 6.19954 5.97063 9.00261L7.03795 8.80479Z" fill="#ffffff"/>
<path d="M0.230334 9.3184L22.3512 5.21854L22.5198 6.12858L0.398999 10.2284L0.230334 9.3184Z" fill="#ffffff"/>
<path d="M1.42828 15.7819L23.5491 11.6821L23.7178 12.5921L1.59695 16.692L1.42828 15.7819Z" fill="#ffffff"/>
<path d="M1.65168 11.4326L0.986685 11.5558L1.55057 14.5983L2.21557 14.475L1.98115 13.2102L2.16804 13.1756L3.06311 14.3179L3.84545 14.1729L2.67458 12.731L3.21202 11.1434L2.44706 11.2851L2.05898 12.6114L1.87643 12.6452L1.65168 11.4326Z" fill="#ffffff"/>
<path d="M3.59964 12.645C3.80505 13.7533 4.32234 14.143 5.17423 13.9851C6.00873 13.8304 6.35766 13.3117 6.14661 12.173C5.94361 11.0777 5.51952 10.6573 4.58071 10.8313C3.71578 10.9916 3.39664 11.5497 3.59964 12.645ZM4.30021 12.5197C4.1689 11.8112 4.30666 11.5114 4.68479 11.4414C5.08031 11.3681 5.30829 11.5551 5.44765 12.307C5.5862 13.0546 5.43524 13.3073 5.07015 13.375C4.70505 13.4427 4.44037 13.2759 4.30021 12.5197Z" fill="#ffffff"/>
<path d="M6.94108 13.5992L7.60608 13.4759L7.37488 12.2285L8.34412 12.0489L8.57532 13.2963L9.24031 13.1731L8.67643 10.1306L8.01143 10.2539L8.23135 11.4404L7.26211 11.6201L7.04219 10.4335L6.3772 10.5567L6.94108 13.5992Z" fill="#ffffff"/>
<path d="M9.8995 13.0509L11.8206 12.6948L11.9253 13.2599L12.5077 13.1519L12.2902 11.9784L11.9816 12.0356L11.5305 9.60163L10.8655 9.72488L11.3166 12.1588L10.4517 12.3191L10.0006 9.88518L9.33561 10.0084L9.8995 13.0509Z" fill="#ffffff"/>
<path d="M12.8664 12.501L14.6919 12.1627L14.5791 11.5542L13.4186 11.7693L13.2986 11.1217L14.3113 10.934L14.2058 10.3646L13.1931 10.5523L13.0803 9.94379L14.2364 9.72951L14.1236 9.12102L12.3025 9.45855L12.8664 12.501Z" fill="#ffffff"/>
<path d="M15.2391 12.0613L15.9041 11.938L15.7438 11.0731C15.8358 11.0605 15.8975 11.0536 15.9888 11.0367C16.7624 10.8933 17.0354 10.474 16.8888 9.683C16.7454 8.90935 16.3959 8.67292 15.5874 8.82275C15.1789 8.89847 15.0343 8.94325 14.6768 9.02749L15.2391 12.0613ZM15.6358 10.4907L15.4465 9.46927C15.5354 9.43932 15.5867 9.4253 15.678 9.40839C16.0344 9.34233 16.1527 9.49573 16.218 9.84778C16.284 10.2042 16.2076 10.3982 15.8816 10.4586C15.7817 10.4771 15.7331 10.4816 15.6358 10.4907Z" fill="#ffffff"/>
<path d="M18.2773 11.4982L18.9423 11.3749L18.4912 8.94094L19.204 8.80883L19.0912 8.20033L17.0006 8.5878L17.1134 9.1963L17.8262 9.06419L18.2773 11.4982Z" fill="#ffffff"/>
<path d="M20.1077 11.1364C20.3706 11.1237 20.6346 11.0927 20.978 11.0291C21.7951 10.8776 21.9826 10.4338 21.8513 9.72533C21.7159 8.99514 21.3683 8.74487 20.7816 8.85362C20.6295 8.88181 20.5129 8.90791 20.3901 8.94865L20.2129 7.99245L19.5479 8.1157L20.1077 11.1364ZM20.6688 10.4525L20.4972 9.52672C20.5695 9.50433 20.6209 9.49032 20.6947 9.47662C20.986 9.42265 21.1072 9.49459 21.1773 9.87273C21.2433 10.2291 21.164 10.3832 20.8815 10.4356C20.8076 10.4493 20.7495 10.451 20.6688 10.4525ZM22.2849 10.7554L22.9499 10.6321L22.386 7.58968L21.7211 7.71293L22.2849 10.7554Z" fill="#ffffff"/>
<path d="M12.8672 3.00456L12.8451 3.00266C12.624 2.98391 12.4296 3.14794 12.4108 3.36902C12.3921 3.59011 12.5561 3.78453 12.7772 3.80327L12.7971 3.80499L12.8171 3.80676C13.038 3.82669 13.2333 3.66371 13.2533 3.44273C13.2732 3.22175 13.1102 3.02645 12.8892 3.00652L12.8672 3.00456Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M14.4053 3.28946C14.192 3.22818 13.9695 3.35137 13.9082 3.56461C13.8469 3.77786 13.9701 4.00041 14.1834 4.06169L14.2214 4.07274C14.4343 4.13519 14.6576 4.01322 14.72 3.80031C14.7824 3.5874 14.6605 3.36419 14.4476 3.30174L14.4053 3.28946Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M11.3874 3.81708C11.608 3.79375 11.768 3.59598 11.7447 3.37533C11.7214 3.15468 11.5236 2.99472 11.3029 3.01804L11.2592 3.02279C11.0387 3.04732 10.8798 3.24596 10.9043 3.46648C10.9288 3.687 11.1275 3.84588 11.348 3.82135L11.3874 3.81708Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M9.98886 4.10666C10.2006 4.04044 10.3186 3.81508 10.2524 3.60332C10.1862 3.39156 9.9608 3.27358 9.74904 3.3398C9.73501 3.34419 9.721 3.34861 9.707 3.35307C9.49561 3.42046 9.37886 3.64645 9.44624 3.85785C9.51363 4.06925 9.73962 4.18599 9.95102 4.11861L9.98886 4.10666Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M15.8797 3.87617C15.6827 3.77417 15.4402 3.85123 15.3383 4.04828C15.2363 4.24532 15.3133 4.48774 15.5104 4.58973L15.5456 4.60807C15.742 4.71114 15.9849 4.63541 16.0879 4.43892C16.191 4.24244 16.1153 3.9996 15.9188 3.89654C15.9058 3.88971 15.8928 3.88292 15.8797 3.87617Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M8.67397 4.66518C8.86869 4.55882 8.94031 4.31474 8.83395 4.12002C8.72758 3.9253 8.4835 3.85368 8.28878 3.96004L8.25014 3.98129C8.05601 4.08871 7.98571 4.33318 8.09313 4.52731C8.20056 4.72145 8.44502 4.79175 8.63916 4.68432L8.67397 4.66518Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M17.2101 4.74056C17.0368 4.60199 16.784 4.63015 16.6454 4.80344C16.5069 4.97673 16.535 5.22953 16.7083 5.3681L16.7393 5.39299C16.9118 5.53249 17.1647 5.50572 17.3043 5.33318C17.4438 5.16065 17.417 4.90769 17.2444 4.76819L17.2101 4.74056Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M7.49258 5.46956C7.66293 5.3274 7.68579 5.07406 7.54363 4.90371C7.40147 4.73335 7.14813 4.7105 6.97778 4.85266L6.94398 4.88102C6.7744 5.02409 6.75292 5.27756 6.896 5.44714C7.03908 5.61672 7.29254 5.6382 7.46212 5.49512L7.49258 5.46956Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M18.3462 5.84726C18.2031 5.67768 17.9496 5.6562 17.78 5.79928C17.6105 5.94236 17.589 6.19582 17.7321 6.3654L17.7576 6.39586C17.8998 6.56621 18.1531 6.58907 18.3235 6.44691C18.4938 6.30475 18.5167 6.05141 18.3745 5.88106L18.3462 5.84726Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M6.48971 6.48792C6.62921 6.31539 6.60244 6.06243 6.4299 5.92293C6.25737 5.78343 6.00441 5.8102 5.86491 5.98274L5.83728 6.0171C5.69871 6.19039 5.72687 6.4432 5.90016 6.58176C6.07345 6.72033 6.32625 6.69217 6.46482 6.51888L6.48971 6.48792Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M5.70479 7.68162C5.80785 7.48514 5.73213 7.2423 5.53564 7.13923C5.33916 7.03616 5.09632 7.11189 4.99326 7.30838C4.98643 7.32139 4.97964 7.33442 4.97288 7.34747C4.87089 7.54452 4.94795 7.78693 5.145 7.88892C5.34204 7.99091 5.58446 7.91386 5.68645 7.71681L5.70479 7.68162Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M5.16946 9.00576C5.23191 8.79285 5.10994 8.56963 4.89703 8.50718C4.68412 8.44473 4.46091 8.56671 4.39846 8.77961L4.38618 8.82189C4.3249 9.03513 4.44809 9.25768 4.66133 9.31897C4.87458 9.38025 5.09713 9.25706 5.15841 9.04381L5.16946 9.00576Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M19.9377 13.3086C19.999 13.0953 19.8758 12.8728 19.6626 12.8115C19.4493 12.7502 19.2268 12.8734 19.1655 13.0867L19.1544 13.1247C19.092 13.3376 19.214 13.5608 19.4269 13.6233C19.6398 13.6857 19.863 13.5638 19.9254 13.3509L19.9377 13.3086Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M19.351 14.783C19.453 14.5859 19.3759 14.3435 19.1789 14.2415C18.9819 14.1396 18.7394 14.2166 18.6375 14.4137L18.6191 14.4488C18.516 14.6453 18.5918 14.8882 18.7883 14.9912C18.9847 15.0943 19.2276 15.0186 19.3306 14.8221L19.351 14.783Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M5.77702 14.9843L5.17735 15.0954C5.30074 15.1948 5.47685 15.2155 5.62403 15.1341C5.69006 15.0975 5.74176 15.0451 5.77702 14.9843Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M18.4866 16.1134C18.6252 15.9401 18.597 15.6873 18.4237 15.5487C18.2505 15.4101 17.9976 15.4383 17.8591 15.6116L17.8342 15.6425C17.6947 15.8151 17.7215 16.068 17.894 16.2075C18.0665 16.347 18.3195 16.3203 18.459 16.1477L18.4866 16.1134Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M6.56628 15.7346C6.42412 15.5643 6.17078 15.5414 6.00043 15.6836C5.83007 15.8257 5.80722 16.0791 5.94938 16.2494L5.97774 16.2832C6.12082 16.4528 6.37428 16.4743 6.54385 16.3312C6.71344 16.1881 6.73492 15.9346 6.59184 15.7651L6.56628 15.7346Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M17.3799 17.2495C17.5495 17.1064 17.571 16.8529 17.4279 16.6833C17.2848 16.5137 17.0314 16.4923 16.8618 16.6353L16.8313 16.6609C16.661 16.8031 16.6381 17.0564 16.7803 17.2268C16.9224 17.3971 17.1758 17.42 17.3461 17.2778L17.3799 17.2495Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M7.58464 16.7375C7.41211 16.598 7.15915 16.6247 7.01965 16.7973C6.88015 16.9698 6.90692 17.2228 7.07946 17.3623L7.11382 17.3899C7.28711 17.5285 7.53992 17.5003 7.67848 17.327C7.81705 17.1537 7.78889 16.9009 7.6156 16.7624L7.58464 16.7375Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M16.0738 18.1492C16.2679 18.0418 16.3382 17.7973 16.2308 17.6032C16.1233 17.409 15.8789 17.3387 15.6847 17.4461L15.6499 17.4653C15.4552 17.5717 15.3836 17.8157 15.49 18.0104C15.5963 18.2052 15.8404 18.2768 16.0351 18.1704L16.0738 18.1492Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M8.77834 17.5224C8.58186 17.4193 8.33902 17.4951 8.23595 17.6915C8.13288 17.888 8.20861 18.1309 8.4051 18.2339L8.44419 18.2543C8.64124 18.3563 8.88365 18.2792 8.98564 18.0822C9.08763 17.8851 9.01057 17.6427 8.81353 17.5407L8.77834 17.5224Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M14.6169 18.7774C14.8283 18.71 14.945 18.484 14.8777 18.2726C14.8103 18.0612 14.5843 17.9445 14.3729 18.0119L14.335 18.0238C14.1233 18.09 14.0053 18.3154 14.0715 18.5271C14.1377 18.7389 14.3631 18.8569 14.5749 18.7907C14.5889 18.7863 14.6029 18.7819 14.6169 18.7774Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M10.1025 18.0577C9.88957 17.9953 9.66635 18.1172 9.6039 18.3302C9.54145 18.5431 9.66343 18.7663 9.87633 18.8287L9.91861 18.841C10.1319 18.9023 10.3544 18.7791 10.4157 18.5659C10.477 18.3526 10.3538 18.1301 10.1405 18.0688L10.1025 18.0577Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M13.0647 19.1077C13.2852 19.0831 13.4441 18.8845 13.4196 18.664C13.3951 18.4435 13.1964 18.2846 12.9759 18.3091L12.9365 18.3134C12.7159 18.3367 12.5559 18.5345 12.5792 18.7551C12.6025 18.9758 12.8003 19.1357 13.021 19.1124L13.0647 19.1077Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M11.5068 18.3237C11.2859 18.3038 11.0906 18.4668 11.0706 18.6877C11.0507 18.9087 11.2137 19.104 11.4347 19.1239L11.4567 19.1259L11.4788 19.1278C11.6999 19.1466 11.8943 18.9825 11.9131 18.7614C11.9318 18.5404 11.7678 18.3459 11.5467 18.3272L11.5268 18.3255L11.5068 18.3237Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M22.0285 12.1567L20.7829 12.3875C20.0955 16.8968 16.0115 20.1551 11.4033 19.7519C8.44458 19.4931 5.96072 17.7852 4.58976 15.3887L3.34338 15.6197C4.85432 18.5388 7.7816 20.6449 11.2983 20.9525C16.6832 21.4237 21.4394 17.5021 22.0285 12.1567Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M20.8416 6.24616L19.5813 6.47974C18.1827 4.22111 15.7734 2.62735 12.9234 2.37801C8.41795 1.98384 4.41356 5.08961 3.59479 9.44266L2.33539 9.67608C3.06472 4.48327 7.74376 0.715019 13.0284 1.17737C16.4382 1.47568 19.2938 3.4647 20.8416 6.24616Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M17.4155 13.0119C16.5614 15.32 14.2453 16.8685 11.6752 16.6436C10.1819 16.513 8.87702 15.8076 7.95934 14.7645L6.92486 14.9562C7.99841 16.4003 9.66098 17.3965 11.5946 17.5656C14.7762 17.844 17.6239 15.79 18.4496 12.8203L17.4155 13.0119Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M7.03795 8.80479C7.9785 6.67134 10.1994 5.27165 12.6514 5.48617C14.0237 5.60623 15.2369 6.21163 16.1366 7.11845L17.204 6.92063C16.1294 5.61384 14.5506 4.72326 12.7321 4.56416C9.66127 4.2955 6.9015 6.19954 5.97063 9.00261L7.03795 8.80479Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M0.230334 9.3184L22.3512 5.21854L22.5198 6.12858L0.398999 10.2284L0.230334 9.3184Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M1.42828 15.7819L23.5491 11.6821L23.7178 12.5921L1.59695 16.692L1.42828 15.7819Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M1.65168 11.4326L0.986685 11.5558L1.55057 14.5983L2.21557 14.475L1.98115 13.2102L2.16804 13.1756L3.06311 14.3179L3.84545 14.1729L2.67458 12.731L3.21202 11.1434L2.44706 11.2851L2.05898 12.6114L1.87643 12.6452L1.65168 11.4326Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M3.59964 12.645C3.80505 13.7533 4.32234 14.143 5.17423 13.9851C6.00873 13.8304 6.35766 13.3117 6.14661 12.173C5.94361 11.0777 5.51952 10.6573 4.58071 10.8313C3.71578 10.9916 3.39664 11.5497 3.59964 12.645ZM4.30021 12.5197C4.1689 11.8112 4.30666 11.5114 4.68479 11.4414C5.08031 11.3681 5.30829 11.5551 5.44765 12.307C5.5862 13.0546 5.43524 13.3073 5.07015 13.375C4.70505 13.4427 4.44037 13.2759 4.30021 12.5197Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M6.94108 13.5992L7.60608 13.4759L7.37488 12.2285L8.34412 12.0489L8.57532 13.2963L9.24031 13.1731L8.67643 10.1306L8.01143 10.2539L8.23135 11.4404L7.26211 11.6201L7.04219 10.4335L6.3772 10.5567L6.94108 13.5992Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M9.8995 13.0509L11.8206 12.6948L11.9253 13.2599L12.5077 13.1519L12.2902 11.9784L11.9816 12.0356L11.5305 9.60163L10.8655 9.72488L11.3166 12.1588L10.4517 12.3191L10.0006 9.88518L9.33561 10.0084L9.8995 13.0509Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M12.8664 12.501L14.6919 12.1627L14.5791 11.5542L13.4186 11.7693L13.2986 11.1217L14.3113 10.934L14.2058 10.3646L13.1931 10.5523L13.0803 9.94379L14.2364 9.72951L14.1236 9.12102L12.3025 9.45855L12.8664 12.501Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M15.2391 12.0613L15.9041 11.938L15.7438 11.0731C15.8358 11.0605 15.8975 11.0536 15.9888 11.0367C16.7624 10.8933 17.0354 10.474 16.8888 9.683C16.7454 8.90935 16.3959 8.67292 15.5874 8.82275C15.1789 8.89847 15.0343 8.94325 14.6768 9.02749L15.2391 12.0613ZM15.6358 10.4907L15.4465 9.46927C15.5354 9.43932 15.5867 9.4253 15.678 9.40839C16.0344 9.34233 16.1527 9.49573 16.218 9.84778C16.284 10.2042 16.2076 10.3982 15.8816 10.4586C15.7817 10.4771 15.7331 10.4816 15.6358 10.4907Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M18.2773 11.4982L18.9423 11.3749L18.4912 8.94094L19.204 8.80883L19.0912 8.20033L17.0006 8.5878L17.1134 9.1963L17.8262 9.06419L18.2773 11.4982Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
<path d="M20.1077 11.1364C20.3706 11.1237 20.6346 11.0927 20.978 11.0291C21.7951 10.8776 21.9826 10.4338 21.8513 9.72533C21.7159 8.99514 21.3683 8.74487 20.7816 8.85362C20.6295 8.88181 20.5129 8.90791 20.3901 8.94865L20.2129 7.99245L19.5479 8.1157L20.1077 11.1364ZM20.6688 10.4525L20.4972 9.52672C20.5695 9.50433 20.6209 9.49032 20.6947 9.47662C20.986 9.42265 21.1072 9.49459 21.1773 9.87273C21.2433 10.2291 21.164 10.3832 20.8815 10.4356C20.8076 10.4493 20.7495 10.451 20.6688 10.4525ZM22.2849 10.7554L22.9499 10.6321L22.386 7.58968L21.7211 7.71293L22.2849 10.7554Z" stroke="#ffffff" strokeWidth="0.392966" mask="url(#path-1-outside-1)"/>
</svg>
  );
}
