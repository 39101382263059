import "./styles.scss";
interface RowProps {
  title: string;
  inverted?: boolean;
  color: string;
  setFilterOption?: (option: string) => void;
}

const Row: React.FC<RowProps> = ({
  title,
  color,
  inverted,
  setFilterOption,
}) => {
  const handleClick = () => {
    if (inverted) {
      setFilterOption(color);
    }
  };
  return (
    <div className="event-map__row" onClick={handleClick}>
      <div className="event-map__circle_container">
        <div className="event-map__circle" style={{ backgroundColor: color }} />
      </div>
      <div className="event-map__row-title">
        {title}
        {inverted && "₽"}
      </div>
    </div>
  );
};

export default Row;
