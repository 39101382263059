import { Place } from "src/types/tickets";

export enum ActionTypes {
  ADD_ITEM_TO_BASKET = "ADD_ITEM_TO_BASKET",
  REMOVE_ITEM_FROM_BASKET = "REMOVE_ITEM_FROM_BASKET",
}

interface AddItem {
  type: ActionTypes.ADD_ITEM_TO_BASKET;
  payload: Place;
}

interface RemoveItem {
  type: ActionTypes.REMOVE_ITEM_FROM_BASKET;
  payload: Place;
}

export type Action = RemoveItem | AddItem;
